.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 30px !important;
}

.ant-empty-image img {
    height: 50% !important;
}

.default-user-empty .ant-empty-description {
    margin: 0;
    color: #bcbcbc;
    transform: translateY(-40px);
}