.scene-controls {
  //max-height: calc(100vh - 300px);
  max-height: calc(100vh - 370px);
  overflow-y: scroll;
  // max-width: 350px;
  overflow-x: scroll;
}
/* width */
.scene-controls::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.scene-controls::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scene-controls::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.81);
}

/* Handle on hover */
.scene-controls::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.73);
}
