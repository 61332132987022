.house-image {
    background: white;
    position: absolute;
    top: 38%;
    left: 44%;
    z-index: 1;
}

.bottom-line {
    position: absolute;
    top: 49%;
    left: 49%;
    border-radius: 0 20px 0 0;
}

.left-line {
    position: absolute;
    top: 0;
    left: 49%;
    border-radius: 20px 0 0 0;
}