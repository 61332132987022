.product {
  margin-top: 20px;
  .create-product {
    font-size: 3rem;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    padding-top: 14px;
  }
  .est-price-box {
    width: 332px;
    height: 60px;
    border-radius: 4px;
    background-color: #f4f4f4;
    font-size: 2rem;
    padding: 14px;
    font-weight: 700;
    .est-text {
      color: #999999;
    }
    .est-price {
      float: right;
      color: #25d6a4;
    }
  }
}
.store-bd {
  color: #999999;
  &:hover {
    text-decoration: underline;
    color: #999999;
  }
}
.product-add-progress {
  .ant-progress-inner {
    margin-top: -15px;
    border-radius: 0px;
  }
}
.product-modal-est-price {
  color: #999999;
}
.product-modal-est-value {
  color: #25d6a4;
  font-size: 3rem;
}
.ant-drawer-body {
  padding: 0px !important;
}
.heading-filter {
  height: 50px;
  background: #f4f4f4;
  p {
    color: #131415;
    font-size: 1.5rem;
    padding-left: 24px;
    padding-top: 12px;
  }
}
.filter-item-padding {
  padding-left: 24px;
  padding-top: 12px;
}
.my-products {
  .my-product-nav {
    margin-bottom: 20px;
    justify-content: space-between;
    font-weight: 600;
    .heading {
      color: $placeholder-color;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  .product-filters {

  }
}
.product-tab-content-scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 250px);
  width: 100%;
  padding-bottom: 50px ;
}

/* width */
.product-tab-content-scrollable::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.product-tab-content-scrollable::-webkit-scrollbar-track {
  background: #e1e1e1;
}

/* Handle */
.product-tab-content-scrollable::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.81);
}

/* Handle on hover */
.product-tab-content-scrollable::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.73);
}
.model-pending-msg {
  position: absolute;
  top: 120px;
  left: 200px;
}
.product-review-btns {
  position: sticky;
  bottom: 0px;
  right: 0;
  left: 0px;
  width: 100%;
  background-color: #D8D8D8;
  padding: 20px;
  border-radius: 5px;
}

.product-status-review {
  position: absolute;
  bottom: 2px;
  width: calc(100vw - 145px);
  background-color: #D8D8D8;
  padding: 20px;
  border-radius: 5px;
}
.product-status-review-modal {
  position: fixed;
  height: unset;
  padding: 20px;
  width: 100%;
  background-color: white;
  z-index: 9;
  bottom: 0;
  box-shadow: 0px -8px 25px rgba(0, 0, 0, 0.04);
}
.room-status-review-modal {
  position: absolute;
  bottom: 2px;
  width: calc(100vw - 3.1%);
  background-color: #D8D8D8;
  padding: 20px;
  border-radius: 5px;
}

.product-status-review-admin {
  position: absolute;
  bottom: 2px;
  z-index: 2;
  width: calc(100vw - 158px);
  background-color: #D8D8D8;
  padding: 20px;
  border-radius: 5px;
  &.white {
    background: #FFFFFF;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.08);
  }
}


.product-full-screen {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100vh !important;
}
.product-full-screen-card{
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh !important;
}
.product-viewer-normal {
  height: calc(100vh - 200px);
}
.top-full-screen-icon {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1000;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 23px;
}

.product-full-screen-icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.product-category-h3 {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  &.grey{
    color: #999999;
  }
  &.black {
    color: #333333;
    text-transform: capitalize;
    font-weight: 900;
  }
}

.product-model-guide {
  font-family: Manrope;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 20px;
  color: #333333;
}

.bg-tag {
  background: #F4F4F4;
  padding: 6px 10px;
  border-radius: 4px;
  margin-right: 10px;
}
.bg-model {
  background: rgba(0, 0, 0, 0.04);
  background-color:  rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  // width: 188px;
  // height: 40px;
  padding: 10px 16px;

  &.red {
    background: #E54547;
  }
  &.green {
    background: #29B862;
  }
  
}
.bg-model-text {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  vertical-align: middle;
  color: #333333;
  &.white {
    color: white;
  }
}

.attr-btn {
  height: 44px !important;
  width: 188px !important;
  border: none !important;
  border-color: rgba(0, 0, 0, 0.04) !important;
  color: rgba(0, 0, 0, 0.04) !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.accept-btn {
  background: #25D6A4 !important;
  border: none !important;
  color: #25D6A4 !important;
  border-radius: 35px !important;
  height: 44px !important;
  width: 188px !important;
  border: none !important;
}

.accept-btn-text {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 25px;

  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  &.white {
    color: #FFFFFF;
  }
  &.red {
    color: #D93025 !important;
  }
  
}

.reject-btn {
  background: transparent !important;
  //color: #D93025 !important;
  border-color: #D93025 !important;
  border: 1px solid #D93025 !important;
  border-radius: 35px !important;
  height: 44px !important;
  width: 188px !important;
}

.inprogress-btn {
  background: rgba(217, 48, 37, 0.04) !important;
  border: 1px solid #DDDDDD !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; 
  height: 44px !important;
  width: 188px !important;
}

.product-attr-h3 {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  // line-height: 32px;
  text-align: center;
  &.grey{
    color: #999999;
  }
  &.black {
    color: #333333;
    text-transform: capitalize;
    font-weight: 900;
  }
}

.awaiting-models {
  text-align: center;
  margin: auto;
  font-size: 32px;
  font-family: Avenir;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 22%;
  width: 600px;
}

.scene-added-banner{
    width: 100%;
    border-radius: 4px;
    border: 1px solid #237804;
    padding: 16px;
    margin-top: 20px;
    background: #F6FFED;
    display: flex;
    justify-content: space-between;

    .banner-content{
        display: flex;
        align-items: center;
        gap: 8px;

        .banner-content-span {
            font-family: 'Manrope', sans-serif;
            font-size: 14px; 
            color: #237804;
            font-weight: 500;
        }
    }
}