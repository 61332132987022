.product-qa-bg {
    // background-color: #F4F4F4;
}

.product-qa-img-tool-container {
    background: white;
}

.product-qa-toolbar {
    position: relative;
    bottom: 0px;
    justify-content: center;

}

.product-qa-img-grid-card {
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    height: 88px;
    width: 100%;
    justify-content: center;
    margin-right: 20px;

    &.selected {
        border: 1px solid rgba(0, 0, 0, 0.85);
    }

    &.comparison-modal {
        height: 150px;
        width: 150px;

        &.selected {
            border: 1px solid rgba(0, 0, 0, 0.85);
        }
    }
}

.product-qa-tabpane-margin {
    margin: 10px 10px;
    padding: 0px 12px;
    background-color: #FFFFFF;
    border-radius: 4px;

    &.qa {
        margin-bottom: 0px;
        .ant-tabs-nav {
            height: 0px !important;
        }
    }

}

.add-reference-img-popup {}

.product-qa-ref-images {
    width: 100%;
    background-color: white !important;

    .ant-upload {
        width: 100%;
        padding: 0px !important;
        height: auto !important;
        min-height: 56px;
        cursor: pointer;
        border-radius: 0px !important;
        background-color: white !important;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .ant-upload-list-item {
        margin-top: 0px !important;
    }

}


.product-qa-upload-box {
    background-color: rgba(244, 244, 244, 0.5) !important;
    border: 1px dashed #d8d7d7 !important;
    border-radius: 4px !important;
    padding-bottom: 40px;
    text-align: center;

    &.h-176 {
        height: 176px !important;
    }

    &:hover {
        border: 1px dashed #276DD7 !important;
    }

    .ant-legacy-form-item-control {
        line-height: 120% !important;
    }

    .ant-upload-select {
        background-color: transparent !important;
    }

    .ant-upload.ant-upload-select {
        min-height: 0px !important;
        border: none !important;

        &:hover {
            border: none !important;
        }
    }

    .ant-upload-list {
        // position: unset !important;
        position: relative !important;
        left: 0;
        right: 0;
        top: 20px;

        margin: auto;
        width: 250px !important;

        // margin-top: 35px;
        .ant-upload-list-item {
            margin-bottom: 10px;
        }
    }

}


.product-qa-image {
    height: calc(100vh - 270px) !important;
    width: 100% !important;
    object-fit: scale-down;
}

.product-qa-image-zoom {
    height: calc(100vh - 270px) !important;
    width: auto !important;
    object-fit: scale-down;
    margin: auto;
    cursor: -moz-zoom-in; 
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}
#canvas-editor-qa {
    width: 100%;
    height: calc(100vh - 270px);
    /* Adjust this value as needed */
    position: relative;
    overflow: hidden;
}

#collaborate-container {
    display: none;
}


.product-qa-action-bar {
    position: sticky;
    bottom: 0px;
    right: 0;
    left: 0px;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.06);
    padding: 10px 20px;
}

.basic-green-btn {
    border-radius: 2px;
    width: unset !important;
    height: unset !important;
    padding: 0 !important;
    color: #FFFFFF !important;
    background: #389E0D !important;
    border: 1px solid #389E0D !important;
    border-radius: 2px !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

    &.ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        background: #389E0D !important;
        border: 1px solid #389E0D !important;
        text-shadow: none;
        opacity: 0.5 !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.ant-tooltip-disabled-compatible-wrapper {
        background: #389E0D !important;
        border: 1px solid #389E0D !important;
        opacity: 0.5 !important;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.f-14 {
        font-size: 14px !important;
        line-height: 22px;
        padding: 8px 15px !important;
        font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        vertical-align: middle;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
    }

    &:hover {
        color: white !important;
        border: 1px solid #296e0b !important;
        background: #296e0b !important;
    }
}

.basic-blue-btn {
    border-radius: 2px;
    width: unset !important;
    height: unset !important;
    padding: 0 !important;
    color: #FFFFFF !important;
    background: #276DD7 !important;
    border: 1px solid #276DD7 !important;
    border-radius: 2px !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

    &.ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        background: #276DD7 !important;
        border: 1px solid #276DD7 !important;
        text-shadow: none;
        opacity: 0.5 !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.ant-tooltip-disabled-compatible-wrapper {
        background: #276DD7 !important;
        border: 1px solid #276DD7 !important;
        opacity: 0.5 !important;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.f-14 {
        font-size: 14px !important;
        line-height: 22px;
        padding: 8px 15px !important;
        font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        vertical-align: middle;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
    }

    &:hover {
        color: white !important;
        border: 1px solid #276DD7 !important;
        background: #276DD7 !important;
    }
}

.basic-grey-btn {
    border-radius: 2px;
    width: unset !important;
    height: unset !important;
    padding: 0 !important;
    color: #262626 !important;
    background: white !important;
    border: 1px solid #D9D9D9 !important;
    border-radius: 2px !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

    &.ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        background: white !important;
        border: 1px solid #D9D9D9 !important;
        color: #276DD7 !important;
        text-shadow: none;
        opacity: 0.5 !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.ant-tooltip-disabled-compatible-wrapper {
        background: white !important;
        border: 1px solid #D9D9D9 !important;
        color: #276DD7 !important;
        opacity: 0.5 !important;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.f-14 {
        font-size: 14px !important;
        line-height: 22px;
        padding: 8px 15px !important;
        font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        vertical-align: middle;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
    }

    &:hover {
        color: #276DD7 !important;
        border: 1px solid #276DD7 !important;
        background: white !important;
    }
}


.model-detail-product-qa {
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background: white;
    margin: 20px 20px;
}


.model-inprogress-box {
    margin: 20px 20px;
}

.model-info-heading-pos {
    border-bottom: 1px solid #F0F0F0;
    display: flex;
    padding: 16px 20px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
}

.product-qa-comment-icon {
    height: 18px;
    width: 18px;
}

.ar-view-box {
    width: 40%;
    grid-gap: 20px;
    border-radius: 4px;
    border: 1px dashed rgba(39, 109, 215, 0.20);
    background: rgba(39, 109, 215, 0.04);
    padding: 40px;
}

.gg-40 {
    grid-gap: 40px;
}

.breadcrumb-setting-product-qa {
    span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

.annotation-circle {
    border-radius: 100px;
    background: #D93025;
    width: 18px;
    height: 18px;
    position: absolute;
    z-index: 2;
    bottom: 8px;
    right: 8px;
}

.image-loader-qa {
    display: flex;
    justify-content: center;
    font-size: 36px;
    color: black;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 7%;
}

.product-banner {
    display: block;
    z-index: 2;
    margin: 0px 8px;
    position: absolute;
	border-radius: 2px;
	border: 1px solid #ffe58f;
	background:rgba(255,251,230,1);
    padding: 12px;
    &.disp-none {
        display: none;
    }
}

.mb-0-important {
    margin-bottom: 0px !important;
}

.m-20 {
    margin: 20px;
}

.product-qa-tab-overflow {
    overflow-y: auto;
    overflow-x: hidden;
}

.product-comparison-img-tag {
    padding: 4px;
    position: absolute;
    top: 5px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 2px;
    &.left {
        left: 5px;
    }
    &.right {
        right: 5px;
    }
}