
.ant-carousel .slick-dots {
    visibility: hidden;
  }
  
  
  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    color: blue;
    font-size: unset;
  }
  
  .ant-carousel .slick-prev:hover,
  .ant-carousel .slick-next:hover,
  .ant-carousel .slick-prev:focus,
  .ant-carousel .slick-next:focus {
    color: blue;
  }
  .rejection-image{
    width: 100vw !important;
  }
  
  /* .slick-current{
    visibility: inherit !important;
  } */
  /* .slick-slide{
    visibility: hidden;
  } */
  