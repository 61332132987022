.ant-layout-header {
  height: unset !important;
  background: $base-color !important;
  line-height: 24px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding: 12px 56px 12px 18px !important;
}

.sign-up-header {
  background: rgba(255, 255, 255, 1) !important;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  padding: 12px 56px 12px 56px !important;
}
.home-btn {
  // margin-top: -10px;
}
