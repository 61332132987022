.overflowY-hidden {
    overflow-y: hidden;
}

.letter-spacing-pt2 {
    letter-spacing: 0.2px;
}

.results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12%;
    margin-left: 20%;
    margin-right: 20%;

    .results-img {
        height: 160px;
        width: 160px;
        margin-bottom: 20px;
    }
}

// Scored Results Styling

.scored-results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 30%;
    margin-right: 30%;

    .scored-results-img {
        height: 64px;
        width: 64px;
        margin-bottom: 20px;
    }
}

.outlined-button {

    width: max-content;
    height: unset;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: pointer;
    color: #276DD7;
    background-color: #FFFFFF;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 12px;
    padding-top: 12px;
    border-radius: 5px;

    &:hover {
        color: #ffffff;
        background-color: #276DD7;
    }

}

.test-results-container {
    border-radius: 4px;
    height: max-content;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    .title {
        display: flex;
        align-items: center;
        text-align: center;
        padding-left: 20px;
        height: 56px;
        border: 1px solid rgba(0, 0, 0, 0.12);
    }

}

.fail-results-container {
    padding: 12px;
    background-color: rgba(39, 109, 215, 0.08);
    border-radius: 4px;
}

.matrix-container {
    padding: 14px 20px;

    .head-container {
        display: flex;
        justify-content: space-between;
    }

    .notes-container{
        background-color: rgba(39, 109, 215, 0.08);
        padding: 20px;
        border-radius: 4px;
    }
}