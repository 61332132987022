.filterDiv {
    margin-left: 10px;
    margin-right: 8px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.buttonDiv{
    display: flex;
    justify-content: flex-end;
}

.bulkDiv{
    display: flex;
    justify-content: space-around;
    border-style: solid;
    border-color:#e0e0e0;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-top:10px;
    padding-bottom:10px;
}
.attributeDiv{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 10px;
}

