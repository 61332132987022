.user-btn {
  display: inline;
  border-radius: 1%;
  
  padding: 0px;
  a, a:hover {
    display: inline-block;
    color:white;
    font-size: 16px;
    line-height: 24px;
    font-family: Manrope;
    .user-arrow-down {
      font-size: 14px !important;
    }
    .username {
      margin-right: 5px;
    }
   .pic {
     margin-right: 10px;
     height: 36px;
     width: 36px;
   }
  }
}

.scene-creator-user-btn {
  display: inline;
  border-radius: 1%;
  padding-right: 10px;
  padding-left: 22px;
  padding-bottom: 15px;
  padding-top: 18px;
  a, a:hover {
    display: inline-block;
    color:white;
    font-size: 1.5rem;
    .user-arrow-down {
      font-size: 1.5rem !important;
    }
    .username {
      margin-right: 10px;
    }
   .pic {
     margin-right: 10px;
   }
  }
}

.notification-dropdown {
  border-radius: 5%;
  margin-top: 10px;
  margin-right: 35px;
  padding: 0px;
  .notification-btn {
    font-size: 2rem !important;
    margin-right: 15px !important;
    a {
      color: white;
    }
  }

}
.create-new-btn {
  margin: 10px 24px 10px 0px;
}

.continue-btn, continue-btn:active {
  width: 150px !important;
  height: 45px !important;
  border-radius: 35px !important;
  background-color: #25d6a4 !important;
  border-color:  #25d6a4 !important;
  font-size: 1.7rem;
}

.approve-btn, approve-btn:active {
  width: 150px !important;
  border-radius: 35px !important;
  background-color: #25d6a4 !important;
  border-color:  #25d6a4 !important;
  font-size: 1.7rem;
}

.save-btn, save-btn:active {
  width: 150px !important;
  height: 45px !important;
  border-radius: 35px !important;
  background-color: #333333 !important;
  border-color:  #333333 !important;
  font-size: 1.7rem;
}
.cancel-btn, cancel-btn:active {
  width: 150px !important;
  height: 45px !important;
  border-radius: 35px !important;
  background-color: white !important;
  border-color:  white !important;
  font-size: 1.7rem;
  color: #999999 !important;
}

.ant-upload-select {
  padding: 30px !important;
  height: 132px !important;
  border-radius: 4px !important;
  border: solid 1px #d8d7d7 !important;
  background-color: rgba(244, 244, 244, 0.5) !important;
}
.no-swatches {
  height: 120px;
  border-radius: 4px;
  border: solid 1px #d8d7d7;
  background-color: rgba(244, 244, 244, 0.5);
  text-align: center;
  .text-head {
    color: #333333;
  }
  .text-body {
    color: #999999;
  }
}

.ant-modal-footer {
  .ant-btn-primary {
    background-color: #d93025 !important;
    border-color:  #d93025 !important;
    border-radius: 30px;
  }
  .ant-btn {
    border-radius: 30px;
  }
}
.ant-input-group.ant-input-group-compact > .ant-btn.ant-btn-round{
  border-radius: 40px;
}

.more-option-library  {
  width: unset !important;
  height: fit-content !important;
  border: none !important;
  background: #ececec !important;
  color: #777777 !important;
  
  &:hover {
    background: #eeeeee !important;
    border: none !important;
    color: #777777;
  }
  &:focus {
    border: none !important;
    background: #eeeeee !important;
    color: #777777;
  }
  &:active {
    border: none !important;
    background: #eeeeee !important;
    color: #777777;
  }
  &.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active {
    background: #ececec !important;
    opacity: 0.5 !important;
    text-shadow: none;
    color: #777777;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  vertical-align: middle;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-transform: capitalize;
  line-height: 120% !important;
  &.font-16 {
    font-size: 16px !important;
    // padding: 12px 24px !important;
  }
 
  &.font-12 {
    font-size: 12px !important;
    // padding: 12px 24px !important;
    &.small {
      padding: 12px 15px !important;
    }
  }
  &.font-18 {
    font-size: 18px !important;
    // padding: 16px 32px !important;
  }
  &.font-14 {
    font-size: 14px !important;
    // padding: 11px 32px !important;
  }
  &.w-900 {
    font-weight: 900 !important;
  }
}

.scene-info-more{
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  padding: 3px 4px 3px 4px;
  cursor: pointer;
  // position: absolute;
  // top: 12px;
  z-index: 1;
  // right: 12px;
  border-radius: 4px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}
