.overflowY-hidden {
    overflow-y: hidden;
}

.letter-spacing-pt2 {
    letter-spacing: 0.2px;
}

.home2-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12%;
    margin-left: 20%;
    margin-right: 20%;

    .home2-img {
        height: 160px;
        width: 215px;
        margin-bottom: 20px;
    }

    .start-test-button {

        width: max-content;
        height: unset;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        cursor: pointer;
        color: #276DD7;
        background-color: #FFFFFF;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 12px;
        padding-top: 12px;
        border-radius: 5px;

        &:hover {
            color: #ffffff;
            background-color: #276DD7;
        }

    }
}

