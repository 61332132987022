.item-icons {
    &.plus {
        float: right;
        cursor: pointer;
        z-index: 999;
        color: black;
        border: none;
        background: #EEEEEE;
        border-radius: 40px;
        width: 40px;
        height: 40px;
        position: absolute;
        padding: 12px;
        right: 12px;
        top: 12px;
    }

    &.tick {
        float: right;
        cursor: pointer;
        z-index: 999;
        color: white !important;
        border: none;
        background: #276DD7 !important;
        border-radius: 40px;
        width: 40px;
        height: 40px;
        position: absolute;
        padding: 12px;
        right: 12px;
        top: 12px;
    }
}

.item-add-btn {
    &:hover {
        border: none !important;
    }

    &:focus {
        border: none !important;
    }
}

.area-pd-space {
    padding: 0px 16px 0px;
}

.product-img-specs {
    height: 200px;
    object-fit: contain;
}

.product-hover {
    .suggested-product {
        opacity: 0.5;
        cursor: not-allowed !important;
    }
}

