.stacked-project-card {
    position: relative;
    cursor: pointer;
    max-width: 400px;
    margin: 20px 0;
  }
  
  .stacked-project-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .stacked-project-card::before,
  .stacked-project-card .stacked-project-card-inner {
    background-color: #fff;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    transition: transform 0.3s;
    height: 510px;
  }
  
  .stacked-project-card::before,
  .stacked-project-card-inner {
    z-index: 1;
  }
  
  .stacked-project-card-inner {
    position: relative;
    padding: 0rem;
  }
  
  
  .stacked-project-card-top-right::before {
    transform: translate(8px, calc(-1 * 8px));
  }
  
  // .stacked-project-card-top-right::after {
  //   transform: translate(16px, calc(-1 * 16px));
  // }
  
  .stacked-project-card-top::before {
    transform: translate(0px, calc(1 * 0px));
  }
  
  // .stacked-project-card-top::after {
  //   transform: translate(8px, calc(-1 * 10px));
  // }

  .tag-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .tag {
    background: #F0F0F0;
    padding: 5px 10px;
    color: black;
    border-radius: 5px;
    margin: 5px;
  }

  .today-tag {
    background: #F6FFED;
    color: #135200;
  }

  .project-name {
    color: black;
    text-align: start;
    padding: 2px 8px;
    height: 50px;
  }
  
  .status {
    color: black;
    text-align: start;
    padding-left: 8px;
  }

  .projects-nav-bar {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }

  .meta-data {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
    border-radius: 4px;
    background: #F0F0F0;
    color: black;
    align-items: center;
  }