.integration-card {
    height: 380px;
    width: 380px;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    background-color: #f9f8f8;
    padding: 20px;
}
    
.auhtorize-button {
    width: 100px;
    height: 40px;
    color: white;
    background: cornflowerblue;
    font-size: 14px;
    border: none;
    border-radius: 6px;
    margin-top: 24px
}

.integrations-container{
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: start;
    padding: 20px;
    margin-top: 20px;
}