
.image-container {
    position: relative;
    margin-top: 2px;
  }
  
  .image {
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .image-container.selected {
    border: 3px solid black; 
  }
  
  .check-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: black;
    font-size: 20px;
  }
  