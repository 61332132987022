.scene-render-lifestyleimage-viewer-modal {
    .ant-modal {
        height: 100vh;
    }

    top: 2% !important;
    /* Adjust the top position if needed */

    //height: 50vh;
    //width: 100%;

    .ant-modal-content {
        height: 100% !important;
        //width: 100%;
    }

    .ant-modal-body {
        height: 100%;
        padding: 0px 20px 10px 20px;
        //overflow-y: auto;
    }

    .modal-content {
        display: flex;
        align-items: flex-start;
        /* Align items at the start of the container */
        //padding: 20px;
    }

    .image-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns with equal width */
        gap: 10px;
        /* Gap between grid items */
        max-height: calc(65vh - 40px);
        /* Set the maximum height for the scrollable area, subtracting padding and margins */
        overflow-y: auto;
        /* Enable vertical scrolling */
        width: 20%;
        /* Adjust width as needed */
        margin-right: 10px;
        /* Space between image grid and selected image */
    }

    .image-grid-item {
        width: 100%;
        /* Full width of each grid item */
        padding-top: 100%;
        /* Maintain aspect ratio for square grid items (1:1 ratio) */
        position: relative;
    }

    .grid-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        /* Ensure images take full width of their container */
        height: 100%;
        /* Maintain aspect ratio */
        object-fit: cover;
        /* Cover the container maintaining aspect ratio */
        cursor: pointer;
        /* Add cursor pointer */
    }

    .selected-image-container {
        width: 80%;
        /* Adjust width of the container for selected image */
        display: flex;
        flex-direction: column;
        /* Stack image and info vertically */
        align-items: center;
        position: relative;
        /* Ensure proper stacking of image and overlay */
        padding-left: 20px;
        /* Add padding on the left */
    }

    .image-info {
        background-color: rgba(255, 255, 255, 0.7);
        /* Semi-transparent white background */
        padding: 10px;
        border-radius: 5px;
        text-align: left;
        /* Align text to the left */
        width: 100%;
        /* Occupy full width */
        box-sizing: border-box;
        /* Include padding in width calculation */

        h3,
        p {
            margin: 0;
            line-height: 1.2;
            /* Adjust line height as needed */
        }

        p {
            margin-top: 5px;
        }
    }

    .selected-image {
        max-width: 100%;
        max-height: calc(65vh - 40px);
        /* Set the maximum height for the selected image, subtracting padding and margins */
        object-fit: cover;
        /* Ensure the selected image fits inside its container */
        margin-top: 20px;
        /* Add margin between image and info */
    }
}

.life-style-images-carousel-modal {
    .image-list-col {
        .image-list {
            display: flex;
            flex-direction: column;

            .image-item {
                margin-bottom: 16px;
                cursor: pointer;
                position: relative;

                img {
                    max-width: 100%;
                    height: auto;
                }

                .delete-button {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                }

                &.selected {
                    border: 2px solid #1890ff;
                }
            }
        }
    }

    .carousel-col {
        .carousel-content {
            display: flex;
            align-items: center;

            .carousel-nav-button {
                background: transparent;
                border: none;
                font-size: 24px;
                color: #1890ff;
                cursor: pointer;

                &:hover {
                    color: #40a9ff;
                }
            }

            .carousel {
                flex: 1;

                .carousel-card {
                    .image-magnify {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.life-style-images-carousel-modal {
    .image-list {
        .image-item {
            position: relative;
            cursor: pointer;

            img {
                width: 100%;
                height: auto;
                border-radius: 8px;
            }

            &.selected {
                border: 2px solid #1890ff;
                /* Example selected border color */
            }

            .delete-button {
                position: absolute;
                top: 8px;
                right: 8px;
                z-index: 10;
            }
        }
    }

    .carousel-content {
        display: flex;
        align-items: center;

        .carousel-nav-button {
            border: none;
            background: none;
            font-size: 24px;
            color: #000;

            &:hover {
                color: #1890ff;
            }
        }

        .carousel {
            flex: 1;

            .carousel-card {
                padding: 0;

                .image-magnify {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .image-list-col {
        overflow-y: auto;
        height: 100%;
        width: 200px;
        border-right: 1px solid #ddd;
        /* Example border */
    }

    .carousel-col {
        .carousel-content {
            display: flex;
            align-items: center;
        }
    }
}


.image-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.image-item {
    position: relative;
    border: 2px solid transparent;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: border-color 0.3s ease-in-out;
}

.image-item.selected {
    border-color: #1890ff; // Ant Design primary color
}

.image-item img {
    width: 100%;
    height: auto;
    display: block;
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: rgba(255, 255, 255, 1);
    }
}

.carousel-col {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.carousel-content {
    position: relative;
    width: 100%;
}

.carousel-nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }

    &:first-child {
        left: 10px;
    }

    &:last-child {
        right: 10px;
    }
}

.lifestyle-carousel-image {
    //width: 100% !important;
    //height: calc(90vh - 140px) !important;
    // margin: auto;
    //padding-top: 0px;
    object-fit: contain;
    width: 100%;
    height: 80vh;
}

.lifestyle-carousel-card {
    // margin-bottom: 16px;
    // margin-right: 16px;
    //height: calc(80vh - 150px) !important;
    text-align: center;
    border-radius: 4px;
    /////////////////////
    //margin: 8px; /* Space around each item */
    box-sizing: border-box;
    /* Include padding and border in item's total width */
    position: relative;
    /* Positioning context for absolute buttons */
    overflow: hidden;
    /* Ensures buttons don't overflow item */
    height: 70vh;
    /* Limit item height */
    object-fit: contain;
    background: #FAFAFA;

    .ant-card-body {
        height: 100%;
    }
}

.lifestyle-image-collaborate-icon {
    align-items: center;
    padding: 5px;
    text-align: center;
    width: 120px;
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 20px;
    z-index: 1;
    left: 20px;
    border-radius: 4px;
    background: #262626;

    //box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    &.right {
        right: 8px;
    }

    &.green {
        background: #29B862;
    }

    &.white {
        background: white;

        &.border {
            border: 1px solid rgb(240, 240, 240);
        }
    }
}

.lifestyle-image-expand {
    padding: 20px;
    align-items: center;
    background: #eee;
    padding: 8px;
    font-size: 24px;
    text-align: center;
    width: auto;
    display: flex;
    cursor: pointer;
    // position: absolute;
    top: 20px;
    z-index: 1;
    right: 20px;
    border-radius: 4px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.img-fav-icon-modal {
    background: rgba(255, 255, 255, 1);
    padding: 7px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.overlay-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 20px;
    right: 20px;
    // width: calc(100% - 40px);
    z-index: 1;
    gap: 10px;
  }

.text-white {
    color: white;
}

.resolutions-tab {
    background: #ffff;
    padding: 8px;

    .resolutions-tab-span {
        margin: 5px 15px;
    }
}

.carousel-index-display {
    position: absolute;
    bottom: 0;
    left: 100;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    /* Adjust background color and opacity as needed */
    color: white;
    /* Text color */
    padding: 5px 10px 5px 10px;
    /* Padding around the text */
    display: flex;
    justify-content: center;
    /* Center align text horizontally */
    align-items: center;
    /* Center align text vertically */
    text-align: center;
    /* Center align text within the banner */
    pointer-events: none;
    /* Prevent the banner from capturing mouse events */
    z-index: 1;
    /* Ensure the banner is above the image */
    width: auto;
    border-radius: 4px;
    margin: 20px;
}


.lifestyle-img-auto-white-switch {
    position: relative;
    //top: 65px;
    //left: 20px;
    z-index: 1;
    display: flex;

    .ant-switch-checked {
        background-color: rgba(0, 0, 0, 0.8) !important;
        min-width: 55px;
        height: 26px;

        .ant-switch-handle {
            top: 4px;
        }

        .ant-switch-handle::before {
            background-color: rgb(255, 255, 255) !important;
        }
    }

    .ant-switch {
        .ant-switch-handle::before {
            background-color: rgb(0, 0, 0);
        }

        .ant-switch-handle {
            top: 4px;
        }

        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
        min-width: 55px;
        height: 26px;
    }
}

.mt-30 {
    margin-top: 30;
}