
.form-width {
    width: 550px;
}


.sub-heading {
    text-align: center;
    margin-bottom: 28;
}

.info-heading {
    font-size: 20px;
    color: #333333;
}

.btn {
    cursor: pointer;
    width: 140px;
}

.items-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.create-account-button {
    float: right;
    cursor: pointer;
    width: 160px,
}

.back-button {
    float: right;
    cursor: pointer;
    margin-right: 8px;
    border: none;
}

.register-artist-font .ant-form-vertical .ant-form-item-label>label,.register-artist-font .input-form label{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        letter-spacing: 0.005em;
        color: #666666;
}

.sub-section .ant-form-item-label>label{
    font-size: 13px !important;

}