
.group-users-list {
    background-color: #FAFAFA;
    padding: 8px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
    border: 1px solid;
    border-color: #F0F0F0;
    border-radius: 2px;
    margin-top: 10px;

}