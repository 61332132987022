.product-filter-form {
  .ant-select-selection {
    height: 40px !important;
  }
  .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-top: 3px;
  }
  .ant-select-selection__rendered {
    margin-top: 3px;
  }
  .filter-box {
    width: calc(100% - 447px);
  }

}

.color-select-col {
  padding-left: 8px !important;
  padding-top: 8px !important;
}

.input-form .ant-select-selection {
  border-radius: 4px!important;
  border: 1px solid #e3e3e3!important;
  background-color: hsla(0,0%,95.7%,.5)!important;
  height: 50px!important;
  color: #333333;
}

.form-category {
  .ant-legacy-form-item-control {
    line-height: 100% !important;
  }
}

.ant-legacy-form{
  font-size: 10px;
  .ant-legacy-form-item{
    display: block ;
  }
  .ant-legacy-form-item-label {
    line-height: 20px !important;
  }
  .ant-legacy-form-explain {
    margin-top: 5px;
    text-align: left;
    font-size: 12px !important;
  }
  // .ant-legacy-form-item-label > label, label {
  //   font-size: 16px !important;
  // }
  .ant-select-selection-placeholder{
    position: absolute;
  }
  .ant-select-multiple .ant-select-selection-placeholder{
    top: 50%;
  }
  .ant-select {
    flex: 1 1;
    overflow: hidden;
    color: #bfbfbf;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .medium-custom-select {
    overflow: visible !important;
  }
  .custom_dimentions_unit {
    overflow: visible !important;
  }
}

.input-form-2 {

  .ant-input {
    border-radius: 4px !important;
    border: solid 1px #e3e3e3 !important;
    background-color: rgba(244, 244, 244, 0.5) !important;
    height: 40px !important;
    color: #333333;
  }
  .ant-select-selection, .ant-select-selector {
    border-radius: 4px !important;
    border: solid 1px #e3e3e3 !important;
    background-color: rgba(244, 244, 244, 0.5) !important;
    height: 40px !important;
    color: #333333;
    padding-top: 5px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    padding-top: 4px;
  }
}


.input-form {
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #333333 ;
    border-color: #333333 ;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #333333;
  }
  .ant-input {
    border-radius: 4px !important;
    border: solid 1px #e3e3e3 !important;
    background-color: rgba(244, 244, 244, 0.5) !important;
    height: 40px !important;
    color: #333333;
  }

  .medium-input-box{
    border-radius: 4px !important;
    border: solid 1px #e3e3e3 !important;
    background-color: rgba(244, 244, 244, 0.5) !important;
    height: 40px !important; 
    color: #464545;
  }
  
  label {
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #333333;
  }
  .ant-form-item-required::before {
    color: #333333;
  }
  .ant-select-selection, .ant-select-selector {
    border-radius: 4px !important;
    border: solid 1px #e3e3e3 !important;
    background-color: rgba(244, 244, 244, 0.5) !important;
    height: 40px !important;
    min-height: 40px !important;
    max-height: max-content !important;
    color: #333333;
    align-items: center;
  }

  .custom_dimentions_unit{
      .ant-select-selection, .ant-select-selector {
        border-radius: 4px !important;
        border: solid 1px #e3e3e3 !important;
        background-color: rgba(244, 244, 244, 0.5) !important;
        height: 32px !important;
        color: #333333;
    }
  }

  .medium-custom-select{
    .ant-select-selection, .ant-select-selector {
      border-radius: 4px !important;
      border: solid 1px #e3e3e3 !important;
      background-color: rgba(244, 244, 244, 0.5) !important;
      height: 40px !important;
      color: #333333;
    }
  }

  .medium-custom-select-tag{
    .ant-select-selection, .ant-select-selector {
      border-radius: 4px !important;
      border: solid 1px #e3e3e3 !important;
      background-color: rgba(244, 244, 244, 0.5) !important;
      height: auto !important;
      color: #333333;
    }
  }


  textarea.ant-input {
    height: 100px !important;
  }
  .ant-select-multiple .ant-select-selection__rendered {
    margin-top: 8px;
  }
  .ant-select-selection__rendered {
    margin-top: 8px;
  }

  .ant-upload-select-picture-card {
    width: 377px !important;
    border-radius: 4px !important;
    border: solid 1px #d8d7d7 !important;
    background-color: rgba(244, 244, 244, 0.5) !important;
  }
  .ant-upload {
    width: 100%;
  }
  .up-info {
    margin-top: 8px;
    .highlight-text {
      color: #4773E2;
      font-weight: 600;
    }
  }
  .ant-upload-list {
    position: absolute;
    left: 35px;
    top:0px
  }

  .add-more-icon {
    position: absolute;
    right: 25px;
    top: 0px;
    color: #d93025;
  }

  .attachment-icon {
    position: absolute;
    right: 25px;
    top: 0px;
    color: BLACK;
    font-size: 22px;
  }
}
.signup-form {
  .ant-upload-list {
      position: static !important;
      margin-bottom: 20px;
      top: 0px;
    }
}


.heading-form-lg {
  color: #276dd7;
  font-style: normal;
  font-weight: 900;
  font-size: 2rem;
  line-height: 100%;
  font-family: Avenir;
}

.form-state-incomplete {
  height: 100%;
  border: solid 1px red;
  padding: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .info {
    font-weight: 700;
    text-align: center;
    color: #d93025;
  }
}

.form-state-disabled {
  height: 100%;
  border: solid 1px gray;
  padding: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .info {
    font-weight: 700;
    text-align: center;
    color: gray;
  }
}

.form-state-complete {
  height: 40px;
  padding: 8px;
  border: solid 1px #25d6a4;
  text-align: center;
  .done {
    font-weight: 700;
    text-align: center;
    color: #25d6a4;
  }
}
.verify-email-form {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}
#product_reference_guidelines {
  height: 100px !important;
}
#root{
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 38px;
  }

  .custom_dimentions_unit .ant-select-selector .ant-select-selection-item {
    line-height: 30px;
  }

  .medium-custom-select .ant-select-selector .ant-select-selection-item {
    line-height: 38px;
  }
  
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 40px;
  }
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #fafafa !important;
  color: #141414;
}

.ant-select-tree{ //remove soon
  font-size: 10px !important;
}

.input-number {
  border-radius: 4px !important;
  border: solid 1px #e3e3e3 !important;
  background-color: rgba(244, 244, 244, 0.5) !important;
  height: 40px !important;
  width: 100% !important;
  line-height: 45px !important;

  &.form-input-number{
    line-height: 120% !important;
  }
}

.ant-legacy-form-item label{
  font-weight: 600;
}


.modal-form {
  border-color: #CCCCCC;

  .ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 4px;
      height: auto;
      padding: 8px 11px;
    }
  }

  .ant-input {
    border-radius: 2px;
    height: auto;
    padding: 8px 11px;
  }
}