.main-card {
    padding: 12px;
    z-index: unset;
    margin-right: 0px;
}

.dropdown-container {
    position: absolute;
    z-index: 2;
    top: 20px;
    right: 20px;
    left: 0;
}

.tooltips-container {
    display: flex;
    position: absolute;
    bottom: 1px;
    z-index: 2;
    cursor: pointer;
    opacity: 0.8;
    border-radius: 4px;
    left: 10px;
}