.space-main-container{
  padding: 0 40px;
  margin-bottom: 40px;
  margin-top: 12px;

  .ant-image{
    width: 100% !important;
    height: 100% !important;
  }

  .ant-image-img{
    width: 100% !important;
    height: 100% !important;
  }

  & > .ant-image{
    width: 100% !important;
    height: 100% !important;
  }
  & > .ant-image-img{
    width: 100% !important;
    height: 100% !important;
  }
}

.space-main-container .ant-image{
  width: 100% !important;
  height: 100% !important;
}

.space-main-container .ant-image-img{
  width: 100% !important;
  height: 100% !important;
}

.space-qa-img-grid-card {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  height: 88px;
  width: 100%;
  justify-content: center;
  margin-right: 20px;

  &.selected {
    border: 1px solid rgba(0, 0, 0, 0.85);
  }

  &.comparison-modal {
    height: 150px;
    width: 150px;

    &.selected {
      border: 1px solid rgba(0, 0, 0, 0.85);
    }
  }
}

.space-img-grid-img {
  height: 120px;
  width: 180px;
  object-fit: contain;
}

.product-grid-list {
  overflow-y: scroll;
  max-height: calc(100vh - 140px);
  &.qa {
    max-height: calc(100vh - 270px);
  }
  &.qa-editor {
    max-height: calc(100vh - 215px);
  }
}

.summary-img-card {
  &.selected {
    border: 1px solid rgba(0, 0, 0, 0.85);
  }
}

.space-action-bottom-bar {
  position: fixed;
  right: 0px;
  left: 0px;
  padding: 20px 40px;
  border-radius: 4px;
  height: 90px;
  box-shadow: 0px 17px 8px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: flex-end;
  background: #ffffff;
  z-index: 99;

  bottom: -100px;
  transition: transform 0.5s ease-in-out;
  bottom: 0px;
  transform: translateY(0);

  .space-col {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;

    .button-container {
    }
  }
}

.space-action-bottom-bar.show {
  bottom: 0px;
  transform: translateY(0);
  /* Animate to its normal position */
}

.three-sixty-viewer {
  & > * {
    width: 100% !important;
    height: 100% !important;
  }

  .icon {
    width: 40px !important;
    height: 40px !important;
  }
}

.children-full {
  & > * {
    width: 100% !important;
    height: 100% !important;
  }
}

.see-more-txt {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.space-variant-container {
  margin-top: 40px;
  border-top: 1px solid #d9d9d9;
  padding: 40px 0px 0px 0px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
}

.space-lifestyle-carousel-image {
  width: 100% !important;
  height: calc(100vh - 200px) !important;
  margin: auto;
  padding-top: 40px;
}

.blur-image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.inprogress-btn {
  background: #ffffff !important;
  border: 1px solid #dddddd !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  height: 44px !important;
  width: 188px !important;
  font-size: 1rem;
}
.accept-btn-text {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  &.white {
    color: #ffffff;
  }
  &.red {
    color: #d93025 !important;
  }
}
.bottom-bar {
  background-color: #ffffff !important;
  width: calc(100vw - 80px) !important;
  justify-content: flex-end;
}
.model-inprogress-box {
  margin: 40px 20px;
}

.modal-height {
  min-height: 100% !important;
}

.grid-card-border {
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px;
}

.grid-img-card {
  object-fit: contain;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.full-screen-icon-360 {
  position: absolute;
  top: 8px;
  z-index: 8;
  cursor: pointer;
  opacity: 0.8;
  right: 8px;
  margin: 0.5rem;
  height: 44px !important;
  width: 44px !important;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;

  &.added {
    right: 24px !important;
  }
}

.spin-icon-360 {
  position: absolute;
  bottom: 8px;
  z-index: 8;
  cursor: pointer;
  opacity: 0.8;
  right: 8px;
  margin: 0.5rem;
  border-radius: 100%;
  height: 50px !important;
  width: 50px !important;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  &.added {
    right: 24px !important;
  }
}

.basic-detail-container {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  gap: 16px;

  &.gap-32 {
    gap: 45px;
  }

  &.j-s-b {
    justify-content: space-between;
  }

  .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;

    .div-2 {
      width: calc(100% - 32px);
    }
  }

  .col-width-16 {
    width: calc(100% - 16px);
  }

  .icon-instance-node {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }

  .text-bottom-border {
    border-bottom: 1px dashed #262626;
    cursor: pointer;
  }
}

.button-text {

  button {
  font-size: 1.75rem !important;
  }

  span {
    font-size: 1.75rem !important;
  }
}

.asset-grid-height {
  height: calc(100vh - 200px) !important;
}

.loader-size {
  width: 80px !important;
  height: 80px !important;
}


.space-room-viewer-normal {
  height: 100%;
  min-height: 100%;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: #000000 !important;
}

.space-full-screen{
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw !important;
  height: 100vh !important;
}


.space-asset-viewer-modal {
  top: 10px;
  width: 95% !important;

  .ant-modal-body {
      padding: 10px 20px !important;
      min-height: calc(100vh - 20px);
      height: calc(100vh - 20px);

      .ant-tabs-content-holder{
        height: 100% !important;

        .ant-tabs-content{
          height: 100% !important;
        }
      }
     
  }
}
.space-mask-none {

  .ant-image-mask {
    display: none !important;
    opacity: 0 !important;

    &:hover{
      display: none !important;
      opacity: 0 !important;
    }
  }

  .ant-image-mask:hover {
    display: none !important;
    opacity: 0 !important;
  }
}

.space-image-carousel-container{

  .ant-carousel{
    width: 100% !important;
    height: 100% !important;

    &>*{
      width: 100%;
      height: 100% !important;
    }
  }
}

.bg-colored{
  & > * {
     background: rgb(242,242,242) !important;
  }
}

.override-space-carousel{

  .ant-carousel{
    height: calc(100% - 44px) !important;
  }

  .ant-carousel .slick-list{
    height: 100% !important;
  }

  .ant-carousel .slick-list .slick-track{
    height: 100% !important;
  }

  .ant-carousel .slick-slide {
    text-align: center;
    background: rgb(242,242,242) !important;
    overflow: hidden;
    height: 100%;
  }

  .ant-carousel .slick-slide > div {
    height: 100% !important;
  }

  .ant-carousel .slick-slide > div > div {
    height: 100% !important;
  }
  
  .react-transform-component{
    height: 100% !important;
  }

  .react-transform-component > div{
    height: 100% !important;
  }
}

.space-download-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.space-download-button {
  z-index: 1;
  margin-bottom: 16px;
  width: 100%;
}

.space-count-tag{
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 1;
}

.display-img-none{

  .ant-image{
    display: none !important;
  }
}

.full-img{
  .ant-image-preview-wrap{
    background: rgb(242,242,242) !important;
  }
}

.space-btn-large {
    all: unset;
    align-items: center;
    align-self: stretch;
    background-color: #F4F4F4;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    box-sizing: border-box;
    display: flex;
    cursor: pointer;
    gap: 4px;
    height: 50px;
    justify-content: center;
    padding: 6px 15px;
    width: 100%;

    &:hover {
        background-color: #f4f4f4e0;
    }
}

.space-reference-file-card{
  width: 100px;
  height: 100px;
  margin-right: 0.5rem;
  display: flex;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  background: #ffffff;
  .ant-image{
    width: 100% !important;
    height: 100% !important;
  }
  .ant-image-img{
    width: 100% !important;
    height: 100% !important;
  }
  
  .space-image-card{
    object-fit: contain;
    width: 100% !important;
    height: 100% !important;

 
  }

  .link-card{
    width: 100%;
    height: 100%;
    color: #276DD7;
    flex-direction: column;
    cursor: pointer;
    text-align: center;
  }
}

.p-max-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;   /* Limits the content to 2 lines */
  overflow: hidden;        /* Hides the overflowing content */
  text-overflow: ellipsis; /* Adds the ellipsis (...) */
  white-space: normal;     /* Allows the text to wrap within the element */
}

.space-element-card{
  width: 100px;
  height: 100px;

  .ant-card-body{
    width: 100%;
    height: 100%;
  }

  .ant-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.rotate-toggle-div{
  width: fit-content !important;
  height: fit-content !important;
  position: absolute;
  right: 80px;
  bottom: 15px;
  display: flex;
  flex-direction: row;
  opacity: 0.8;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  background: #ffffff;

  .ant-switch-checked{
    background-color: #276dd7 !important;
    
  }

  .ant-switch-checked:focus{
    box-shadow: none;
  }
}

.space-card-action-container{
  position: absolute;
  display: flex;
  bottom: 8px;
  z-index: 8;
  opacity: 0.8;
  right: 8px;
  margin: 0.5rem;
  height: fit-content !important;
  width: fit-content !important;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
}

.spin-icon-360-flex {
  z-index: 8;
  cursor: pointer;
  margin: 0.5rem;
  border-radius: 100%;
  height: 50px !important;
  width: 50px !important;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate-toggle-div-flex{
  width: fit-content !important;
  height: fit-content !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  background: #ffffff;
  margin-top: 8px;
  margin-right: 8px;

  .ant-switch-checked{
    background-color: #276dd7 !important;
    
  }

  .ant-switch-checked:focus{
    box-shadow: none;
  }
}

.space-tabs-override{
  .ant-tabs-top > .ant-tabs-nav{
    margin: 0 !important;
  }
}

.py-none{
  padding-top: 0 !important;
}

.space-centered {
  position: absolute; /* Enables absolute positioning */
}