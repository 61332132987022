.input-id{
    justify-content: center;
}

.input-container {
    position: relative;
    width: 100%;
  }
  
  .custom-input {
    padding-right: 10%; /* Reserve space for the suffix */
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .suffix-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 10%;
    height: 100%;
    background-color: #276DD7;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;
  }
  
  .custom-icon {
    color: white;
    font-size: 16px;
  }

  .input-error {
    border: 1px solid red;
  }

  .manrope {
    font-family: Manrope;
    font-style: normal;
    &.f-36 {
      font-weight: 600;
      font-size: 36px;
      letter-spacing: 0;
      line-height: 120%;
    }
  
    &.f-30 {
      font-weight: 600;
      font-size: 30px;
      letter-spacing: 0;
      line-height: 120%;
    }
  
    &.f-32 {
      font-weight: 600;
      font-size: 32px;
      letter-spacing: 0;
      line-height: 120%;
    }
    &.f-28 {
      font-weight: 500;
      font-size: 28px;
      line-height: 140%;
      display: flex;
      align-items: center;
      text-align: center;
    }
  
    &.f-24 {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      display: flex;
      align-items: center;
      text-align: center;
    }
  
    &.f-20 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      &.margin-top-20{
        margin-top: 20px;
      }
      &.black{
        color: #1F1F1F
      }
    }
  
    &.f-18 {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      &.dollar{
        font-weight: 600;
        color: #276DD7;
      }
      &.black{
        color: #1F1F1F
      }
    }
  
    &.f-16 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      &.w-700{
        font-weight: 700;
      }
      &.w-600{
        font-weight: 600;
      }
      &.w-500{
        font-weight: 500;
      }
      &.black{
        color: #1F1F1F
      }
    }
  
    &.f-14 {
      font-weight: 500;
      font-size: 14px !important;
      line-height: 20px;
      letter-spacing: 2%;
      &.w-400{
        font-weight: 400;
      }
      &.w-600{
        font-weight: 600;
      }
      &.w-500{
        font-weight: 500;
        line-height: 14px !important;
      }
      &.33{
        color:#333333
      }
      &.black{
        color: #1F1F1F
      }
    }
  
    &.f-12 {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0.02em;
      &.w-300 {
        font-weight: 300;
      }
      &.w-500{
        font-weight: 500;
      }
      &.w-600{
        font-weight: 600;
      }
      &.l-140{
        line-height: 140%;
      }
      &.black{
        color: #1F1F1F
      }
    }
  
    &.f-10 {
      font-weight: 500;
      font-size: 10px;
      line-height: 120%;
      letter-spacing: 0.02em;
      &.black{
        color: #1F1F1F
      }
    }
  
    &.f-8 {
      font-weight: 500;
      font-size: 8px;
      line-height: 120%;
      letter-spacing: 0.02em;
    }
  
    &.lh-32 {
      line-height: 32px;
    }
  
    &.clamp-text {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      overflow:hidden;
      width: 145px;
      &.w-100 {
        max-width: 100%;
        width: unset;
      }
      &.w-50 {
        max-width: 50%;
        width: unset;
      }
      &.w-60 {
        width: 60%;
        max-width: 50%;
        width: unset;
      }
      &.w-90 {
        width: 90%;
        text-align: left;
      }
      &.w-50px {
        max-width: 50px;
        width: unset;
      }
      &.w-200px {
        max-width: 200px;
        width: unset;
      }
    }
  
    &.clamp-text-400 {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      overflow:hidden;
      min-width: 100px ;
      max-width: 400px;
    }
  
    &.clamp-w-width {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    &.clamp-w-150 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  
    &.w-400 {
      font-weight: 400;
    }
  
    &.w-600 {
      font-weight: 600;
    }
  
    &.w-700 {
      font-weight: 700;
    }
  
    &.w-900 {
      font-weight: 900;
    }
  
    &.normal-style {
      font-style: normal !important;
    }
  
    &.italic {
      font-style: italic;
    }
  
    &.green {
      color: #009D19;
    }
  
    &.green-75{
      color: #75d8a5;
    }
  
    &.black-55 {
      color: #555555 !important;
  
      .ant-legacy-form-item-label > label {
        color: #555555 !important;
      }
    }
  
    &.blue {
      color: "#276DD7"
    }
  
    &.black-14 {
      color: #141414;
    }
  
    &.black-00 {
      color: #000000;
    }
  
    &.blue-96 {
      color: #096DD9;
    }
  
    &.black-33 {
      color: #333333;
    }
  
    &.red {
      color: #FF4A4D;
    }
  
    &.red-error {
      color: #E54547;
    }
  
    &.red-d9 {
      color: #D93025;
    }
  
    &.red-ff {
      color: #ff4d4f;
    }
  
    &.red-03 {
      color: #D40003;
    }
  
    &.grey-8c {
      color: #8C8C8C;
    }
  
    &.black-26 {
      color: #262626;
    }
    
    &.yellow {
      color: #EDAD15;
    }
  
    &.orange {
      color: #AD6800;
    }
  
    &.grey-73 {
      color: #737373;
      a {
        &:hover {
          text-decoration: underline;
  
        }
      }
    }
  
    &.black-73 {
      color: #00000073;
      &:hover {
        color: #00000073;
        text-decoration: underline;
      }
    }
  
    &.black-d9 {
      color: #000000d9;
    }
  
    &.grey-99 {
      color: #999999;
    }
  
    &.grey-6b {
      color: #6B6B6B;
    }
  
    &.grey-77 {
      color: #777777;
      a {
        &:hover {
          text-decoration: underline;
  
        }
      }
    }
  
    &.lh-28 {
      line-height: 28px;
    }
  
    &.lh-14 {
      line-height: 14px;
    }
  
  
    &.white {
      color: #FFFFFF;
    }
  
    &.capitalize {
      text-transform: capitalize;
    }
  
    &.blue {
      color: #276DD7;
      &.link {
        text-decoration: underline;
        cursor: pointer;
      }
      &.hover-change {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  
    &.j-end {
      display: flex;
      align-items: flex-end;
      text-align: center;
    }
  
    &.j-center {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  
    &.upper-case {
      text-transform: uppercase;
    }
  
    &.underline {
      text-decoration: underline;
    }
  
  }

.custom-tree-select{
    .ant-select-selection-search-input{
        height: 30px !important;
    }
}

.label-data{
    width: 100%;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    line-height: 30px !important;
    padding-left: 11px;
}

.new-product-btn {
    padding: 8px 16px;
    font-family: 'Manrope';
    border-radius: 2px;
    height: unset;
    width: unset;

    &.red {
        border: 1px solid #D93025;
        color: white;
        background: #D93025 !important;

        &:hover {
            border: 1px solid #D93025;
            color: white;
            background: #D93025 !important;
        }
    }
    &.black {
      border: 1px solid #000000;
      color: white;
      background: #000000 !important;

      &:hover {
          border: 1px solid #000000;
          color: white;
          background: #000000 !important;
      }
    }
}

.fade-up-center .ant-modal-content {
  opacity: 0;
  animation: fade-in 0.8s ease-in-out forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Fade Out Animation */
.fade-out .ant-modal-content {
  opacity: 1;
  animation: fade-out 0.4s ease-in-out forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}