.cards-wrapper-subs{
    display: flex;
    padding: 10px 30px;
    flex-wrap: wrap;
}

.float-right{
    float: right;
}

.no-border{
    border: 1.5px solid #E2E2E2;
}

.blue-border{
    border:0.5px solid #276DD7
}

.subscription-package-wrapper .ant-radio-group{
    display: unset;
}


.subscription-package-wrapper .ant-radio-checked .ant-radio-inner:after{
    background-color:#276DD7 !important;
}
