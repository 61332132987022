.heading-container {
    width: 100%;
    height: 120px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF;
    margin-bottom: 24px;

    .heading {
        margin-top: 24px;
        margin-left: 24px;
        color: #333333;
    }

    .remaining-time-container {
        display: flex;
        flex-direction: row;
        .countdown-container{
            width: max-content;
            height: max-content;
            padding: 2px 8px;
            background-color: rgba(225, 155, 18, 0.08);
        }
    }
}


.content-container {

    padding-left: 77px;
    padding-right: 77px;
    padding-bottom: 92px;

    .ant-upload-list-text-container {
        margin-bottom: 5px;

        .ant-upload-list-item {
            //margin-bottom: 20px;

            .ant-upload-list-item-info {
                margin-bottom: 20px;
            }
        }

        .ant-upload-list {
            position: static !important;
            margin-bottom: 20px;
            top: 0px;
        }

        .ant-progress-outer {
            margin-bottom: 10px;
        }
    }

    .upload-container {

        border-radius: 4px;
        height: max-content;
        width: 100%;
        background-color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.12);

        .title {
            display: flex;
            align-items: center;
            text-align: center;
            padding-left: 20px;
            height: 56px;
            border: 1px solid rgba(0, 0, 0, 0.12);
        }

        .dragger {
            padding: 20px;
            width: 100%;

            .dragger-size {
                height: 200px;
            }
        }
    }

    .requirements-container {
        border-radius: 4px;
        height: max-content;
        width: 100%;
        background-color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 20px;

        .title {
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 22px;
            letter-spacing: 0.005em;
            padding-left: 20px;
            height: 56px;
            border: 1px solid rgba(0, 0, 0, 0.12);
        }

        .details-container {
            padding: 20px;
            width: 100%;
        }

    }

    .assignment-container {

        height: 100px;
        width: 100%;

        .details-section {
            border-radius: 4px;
        }

        .title {
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 22px;
            letter-spacing: 0.005em;
            padding-left: 20px;
            height: 56px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            background-color: #FFFFFF;
        }

        .tags-container {
            display: flex;
            align-items: center;
            text-align: center;
            flex-wrap: wrap;
            padding: 20px;
            width: 100%;
            height: max-content;
            background-color: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.12);

            &:hover {
                &::-webkit-scrollbar {
                    width: 0px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, .2)
                }
            }

            .tags {
                padding: 8px;
                margin-top: 8px;
                margin-right: 12px;
                margin-bottom: 8px;
                color: #666666;
                background-color: rgba(0, 0, 0, 0.04);
                border-radius: 2px;
            }

            .values-container {
                height: max-content;
                display: flex;
                align-items: flex-start;
                color: #666666;
                flex-direction: column;
                margin-top: 5px;
                margin-bottom: 5px;
                margin-right: 100px;

                .text-grey {
                    color: #333333;
                }

            }

            .items-container {
                height: max-content;
                display: flex;
                align-items: flex-start;
                color: #666666;
                flex-direction: column;

                .reference-img-container {
                    display: flex;
                    justify-content: space-between;

                    .ref-img {
                        width: 100px;
                        height: 100px;
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}