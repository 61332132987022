.product-nav-header {
    background-color: #FBFBFB !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    &.white {
        background-color: #FFFFFF !important;
        &.white {
            padding-right: 20px !important;
            padding-left: 20px !important;
        }
    }
}

.nav-btns {
    font-family: Manrope;
    padding: 10px;
    width: unset !important;
    height: unset !important;
    border-radius: 2px !important;

    &.ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        background: #FFFFFF !important;
        text-shadow: none;
        opacity: 0.5 !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.red {

        background: white !important;
        border: 1px solid #F5222D !important;
        color: #F5222D !important;

        &:hover {
            background: #F5222D !important;
            border: 1px solid #F5222D !important;
            color: white !important;
        }
    }


    &.grey {

        background: white !important;
        border: 1px solid #737373 !important;
        color: #E4E4E4 !important;

        &:hover {
            background: #E4E4E4 !important;
            border: 1px solid #737373 !important;
            color: white !important;
        }
    }


    &.blue {

        background: white !important;
        border: 1px solid #276DD7 !important;
        color: #276DD7 !important;

        &:hover {
            background: #276DD7 !important;
            border: 1px solid #276DD7 !important;
            color: white !important;
        }
    }

    &.light-blue {

        background: white !important;
        border: 1px solid #276DD7 !important;
        color: #276DD7 !important;

        &:hover {
            background: #E6F7FF !important;
            border: 1px solid #276DD7 !important;
            color: #276DD7 !important;
        }
    }

    &.f-14 {
        font-size: 14px;
        line-height: 14px;
    }

}

.product-btn {
    padding: 12px 16px;
    font-family: 'Manrope';
    border-radius: 2px;
    height: unset;
    width: unset;

    &.small-icon {
        padding: 8px 10px;
    }

    &.small {
        padding: 11px 16px;
    }


    &.br-2 {
        border-radius: 2px;
    }

    &.br-4 {
        border-radius: 4px;
    }


    &.light-blue {
        border: 1px solid #276DD7;
        color: #276DD7;
        background: white !important;

        &:hover {
            border: 1px solid #276DD7;
            color: #276DD7;
            background: #E6F7FF !important;
        }
    }

    &.dark-blue {
        border: 1px solid #276DD7;
        color: white;
        background: #276DD7 !important;

        &:hover {
            border: 1px solid #276DD7;
            color: white;
            background: #276DD7 !important;
        }
    }

    &.red {
        border: 1px solid #D93025;
        color: white;
        background: #D93025 !important;

        &:hover {
            border: 1px solid #D93025;
            color: white;
            background: #D93025 !important;
        }
    }

    &.black {
        border: 1px solid #000000;
        color: white;
        background: #000000 !important;

        &:hover {
            border: 1px solid #000000;
            color: white;
            background: #000000 !important;
        }
    }

    &.disabled-grey {
        border: 1px solid #D9D9D9;
        color: rgba(0, 0, 0, 0.25);
        background: #F5F5F5 !important;

        &:hover {
            border: 1px solid #D9D9D9;
            color: rgba(0, 0, 0, 0.25);
            background: #F5F5F5 !important;
        }
    }

    &.f-12 {
        font-size: 12px;
        line-height: 14px;
    }

    &.f-14 {
        font-size: 14px;
        line-height: 14px;
    }

    &.f-16 {
        font-size: 16px;
        line-height: 16px;
    }

    .pd-16 {
        padding: 16px 20px;
    }
    @media (max-width:1140px){
        padding:10px 5px;
        font-size: 13px !important;
    }
}


.download-divider {
    margin: 0px !important;
}


.frame {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;

    .image-viewer {
        align-items: center;
        background-color: white;
        border: 1px solid;
        border-color: #D9D9D9;
        border-radius: 4px;
        display: flex;
        height: 100%;
        overflow: hidden;
        justify-content: center;
        padding: 0px 6px;
        width: 100%;
    }

    .hero-shot {
        height: calc(100vh - 400px);
        object-fit: contain;
        width: 100%;
        cursor: pointer;
    }

    .button {
        all: unset;
        align-items: center;
        align-self: stretch;
        background-color: #F4F4F4;
        border: 1px solid;
        border-color: rgba(0, 0, 0, 0.85);
        border-radius: 4px;
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
        box-sizing: border-box;
        display: flex;
        cursor: pointer;
        gap: 4px;
        height: 50px;
        justify-content: center;
        padding: 6px 15px;
        width: 100%;

        &:hover {
            background-color: #f4f4f4e0;
        }
    }

    .image-card {
        align-items: center;
        align-self: stretch;
        background-color: white;
        border: 1px solid;
        border-color: #D9D9D9;
        border-radius: 4px;
        display: flex;
        flex: 1;
        flex-grow: 1;
        justify-content: center;
        overflow: hidden;
        padding: 0px 6px;
    }

    .secondary-shot {
        cursor: pointer;
        object-fit: contain;
        max-width: 100%;
        height: 200px;
    }

    .text {
        color: rgba(0, 0, 0, 0.85);
        font-family: Manrope;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        width: fit-content;
    }

    .arrow-right {
        font-size: 16px !important;
        color: rgba(0, 0, 0, 0.85);
    }

}

.product-action-menu-card {
    margin-right: 10px;
    cursor: pointer;
    height: 64px;
    width: 64px;
    padding: 10px;
    border: 1px solid #D9D9D9;
}

.product-action-img {
    object-fit: contain;
    height: 40px;
    width: 40px;
}

.product-action-bar {
    position: absolute;
    left: 20px;
    bottom: 20px;
    display: flex;
}

.product-asset-viewer-modal {
    top: 10px;
    width: 95% !important;

    .ant-modal-body {
        padding: 10px 20px !important;
    }
}

.expand-icon-pos {
    position: absolute;
    right: 18px;
    top: 20px;
    z-index: 1;
    cursor: pointer;
    opacity: 0.8;
    border-radius: 4px;
}

.hero-shot-label {
    position: absolute;
    left: 18px;
    top: 20px;
    z-index: 1;
    cursor: pointer;
    display: inline-flex;
    height: 28px;
    padding: 1px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #F0F0F0;
}


.asset-tab-heading {
    color: rgba(0, 0, 0, 0.85);

    &:hover {
        color: #276DD7 !important;
    }

    &.selected {
        color: #276DD7;
    }
}

.product-img-grid-card {
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    height: 88px;
    width: 88px;
    justify-content: center;
    &.qa-preview {
        width: 42%;
    }
    &.comparison-modal {
        height: 150px;
        width: 150px;
        &.selected {
            border: 1px solid rgba(0, 0, 0, 0.85);
        }
    }

    &.selected {
        border: 1px solid rgba(0, 0, 0, 0.85);
    }
}

.product-img-grid-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.video-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
}

.product-silo-lifestyle-delete-icon {
    position: absolute;
    width: 25px;
    top: 3px;
    right: 3px;
    z-index: 999;
    cursor: pointer;
    opacity: 0.8;
    border-radius: 4px;
}

.gg-16 {
    gap: 16px;
}

.product-carousel-image {
    width: auto !important;
    height: calc(100vh - 160px) !important;
    margin: auto;
}

.product-lifestyle-carousel-image {
    width: auto !important;
    height: calc(90vh - 160px) !important;
    margin: auto;
    padding-top: 40px;
}

.carousel-btns {
    z-index: 1;
    align-items: center;
    background-color: rgba(250, 250, 250, 1);
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    display: flex;
    height: 40px;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    width: 40px;

    .arrow {
        font-size: 16px !important;
        color: #595959;
    }

    &.right-btn {
        position: absolute;
        top: 40%;
        right: 20px;
    }

    &.left-btn {
        position: absolute;
        top: 40%;
        left: 20px;
    }

    &:hover {
        background-color: rgba(250, 250, 250, 0.896);
    }
}


.product-grid-list {
    overflow-y: scroll;
    max-height: calc(100vh - 140px);
    &.qa {
        max-height: calc(100vh - 270px);
    }
    &.qa-editor {
        max-height: calc(100vh - 215px);
    }
}

.canvas-load-height {
    &.qa {
        display: flex;
        justify-content: center;
        height: 270px;
    }
}

.count-tag {
    z-index: 1;
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: inline-flex;
    height: 28px;
    padding: 1px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #262626;
}

.product-img-label {
    z-index: 1;
    position: absolute;
    top: 0px;
    left: 20px;
    display: inline-flex;
    height: 28px;
    padding: 1px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #F0F0F0;

}

.download-button {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 1;
}
.download-button-product {
    position: absolute;
    right: 20px;
    top: 32px;
    z-index: 1;
}


.model-inspector-viewer-customer {
    height: calc(100vh - 140px);
    display: none;
}

.glb-height {
    height: calc(100vh - 140px);
}

.glb-height-qa {
    height: calc(100vh - 270px);
}

.model-inspector-frame-customer {
    width: 100%;
    height: 100%;
    border: none !important
}

.product-viewer-360-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    height: calc(100vh - 130px);
}

.product-viewer-mp4-viewer {
    height: calc(100vh - 140px);
}

.edit-icon-property {
    color: #276DD7;
    font-size: 20px;
    cursor: pointer;
}

.limit-lines {
    max-width: 100%;
    width: unset;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

    &.limit-2 {
        -webkit-line-clamp: 2;
    }

    &.limit-1 {
        -webkit-line-clamp: 1;
    }

    &.w-60 {
        max-width: 60%;
    }
}

.detail-pd {
    padding-left: 3%;
    padding-right: 18px;
}


.basic-detail-container {
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    gap: 16px;

    &.gap-32 {
        gap: 45px;
    }

    &.j-s-b {
        justify-content: space-between;
    }

    .div {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 16px;
        position: relative;

        .div-2 {
            width: calc(100% - 32px);
        }
    }

    .col-width-16 {
        width: calc(100% - 16px);
    }

    .icon-instance-node {
        height: 24px !important;
        position: relative !important;
        width: 24px !important;
    }

    .text-bottom-border {
        border-bottom: 1px dashed #262626;
        cursor: pointer;
    }
}

.lh-16 {
    line-height: 16px;
}

.lh-14 {
    line-height: 14px;
}


.product-action-button-container {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
    margin-top: 30px;
}

.store-product-action-button-container {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
}

.product-info-collapse {
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;

    .ant-collapse-header {
        padding: 12px 0 !important;
    }

    .ant-collapse-content-box {
        padding: 12px 0 !important;
    }
}



.phone-container {
    align-items: flex-start;
    display: flex;
    gap: 40px;
    position: relative;

    .phone-div {
        align-items: flex-start;
        border-radius: 4px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 24px;
        padding: 16px 0px;
        position: relative;
    }

    .name-container {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }
}


.license-container {
    align-items: center;
    background-color: #F4F4F4;
    border-radius: 4px;
    display: inline-flex;
    gap: 8px;
    padding: 16px;
    position: relative;

    .eye-invisible {
        color: #262626;
        font-size: 16px;
    }
}

.reference-section {
    background-color: #F4F4F4;
    gap: 24px;
    padding: 40px 40px;
}

.mt-70 {
    margin-top: 70px;
}


.all3d-logo-dim {
    width: 50px;
    object-fit: contain;
    height: 20px;
}

.not-downloadable {
    width: 16px;
    height: 16px;
    object-fit: contain;
}

.product-card-photo {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.product-card-reference {
    border: 1px solid #CCC;
    background: white;
    max-width: 100px;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}


.complaint-icon {
    width: 16px;
    height: 18px;
}


.product-action-bottom-bar {
    position: fixed;
    right: 0px;
    left: 0px;
    padding: 20px 40px;
    border-radius: 4px;
    background: white;
    box-shadow: 0px 17px 8px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: flex-end;

    bottom: -100px;
    transition: transform 0.5s ease-in-out;
    transform: translateY(100%);

    .product-col {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;

        .button-container {}
    }
}

.product-action-bottom-bar.show {
    bottom: 0px;
    transform: translateY(0);
    /* Animate to its normal position */
}

.product-variant-container {
    margin-top: 40px;
    border-top: 1px solid #D9D9D9;
    padding: 40px 0px 0px 0px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;
}

.product-variant-img {
    width: 100%;
    object-fit: contain;
    height: 252px;
}

.variant-grid-card {
    object-fit: contain;
    width: 90%;
    height: 252px;

    .ant-card-body {
        border: solid 1px #e3e3e3;
    }

}


.react-multi-carousel-list {
    &.variant-grid {
        height: 380px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .react-multiple-carousel__arrow--right {
            top: 35%;
        }

        .react-multiple-carousel__arrow--left {
            top: 35%;
        }
    }
}


.render-in-progress {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-size: 18px;
    text-align: center;
    color: #D93025;
    flex: none;
    order: 0;
    align-self: center;
    padding: 6px 20px;
    top: 40%;
    left: 0;
    right: 0;
    width: max-content;
    margin: auto;
    z-index: 1;
    position: absolute;
}


.no-assets-message {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    border-radius: 4px;
    background: #FFF1B8;
    padding: 16px;
    margin-bottom: 20px;
}


.camera-no-assets {
    font-size: 14px;
    color: #AD6800;
}

.product-section-1 {
    padding-right: 20px;
    padding-left: 20px;
}

.white-space-text {
    white-space: nowrap;
}


.see-more-button {
    height: 358px;
    border: 1px dashed #80808070;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        background-color: #FAFAFA;
    }
}

.see-more-txt {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

.ant-carousel {
    .product-carousel {
        .slick-list .slick-slide>div>div {
            vertical-align: bottom;
            background-color: #f0f0f0cc;
        }
    }
}

.product-carousel-image, .product-lifestyle-carousel-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: zoom-in;
}

.padding-10 {
    padding: 10px !important;
    padding-bottom: 0px !important;
}

.resolutionText {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-right: 0
}

.align-baseline {
    align-items: baseline;
}

.justify-start {
    justify-content: start;
}

.silo-image-collaborate-icon {
    align-items: center;
    padding: 5px;
    text-align: center;
    width: 120px;
    display: flex;
    cursor: pointer;
    position: absolute;
    top: 20px;
    z-index: 1;
    left: 20px;
    border-radius: 4px;
    background: #262626;
    //box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    &.right {
        right: 8px;
    }
    &.green {
        background: #29B862;
    }
    &.white {
        background: white;
        &.border {
            border: 1px solid rgb(240, 240, 240);
        }
    }
}

.bold-name {
    font-weight: bold;
    color: #000; /* Adjust color for better contrast */
}
