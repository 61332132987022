.mb-20 {
    margin-bottom: 20px;
}

.pd-32 {
    padding: 32px;
}

.pd-t-10 {
    padding-top: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mr-20 {
    margin-right: 20px;
}

.analytics-content {
    .ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title {
        padding: 8px 10px 16px 10px;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        z-index: 1;
        color: #1890ff !important;
        background: #fff;
        border-color: #1890ff !important;
        outline: #1890ff !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
        border-color: #1890ff !important;
        background-color: #1890ff !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus {
        box-shadow: none !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        z-index: 1;
        color: #1890ff !important;
        background: #fff;
        border-color: #1890ff !important;
        outline: #1890ff !important;
        outline-color: rgb(24, 144, 255) !important;
        outline-style: initial !important;
        outline-width: initial !important;
    }

    .ant-radio-button-wrapper-checked:focus {
        color: #1890ff !important;
        outline: none;
        box-shadow: none;
    }

    .ant-card {
        width: 100%;
        padding: 0.5rem;
        border-radius: 4px;
        border: 1px solid #e3e3e3;
    }

    .ant-card-body {
        padding: 10px !important;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 30px !important;
    }

    .ant-card-small>.ant-card-head {
        min-height: 36px;
        padding: 0 0px;
        font-size: 16px;
        font-weight: 500;
        font-family: Manrope;
    }
}

.analytics-txt-heading {
    font-size: 24px;
    font-weight: 600;
    font-family: Manrope;
    line-height: 20px;
    color: #333333;
}

.sub-heading {
    font-size: 18px;
    font-weight: 500;
    font-family: Manrope;
    line-height: 20px;
}

.chart-container {
    height: max-content;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 0px;
    padding-top: 20px;
}

.cursor-pointer {
    cursor: pointer;
}

.loading-center {
    color: #ff0000;
    font-size: 36px;
    justify-content: center;
    position: relative;
    left: 48%;

    .top-20 {
        top: 20%
    }
}

.section-container {
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 10px 20px;
    margin-bottom: 20px;
}

.line-plot {
    text-align: center;
    padding: 30px 10px 0px 10px;
}

.disable-click {
    pointer-events: none;
    cursor: default;
}