.card-heading{
    margin: 20px 10px;
}

.float-right{
    float: right;
}

.myProgress {
    width: 100%;
    background: #E3E3E3;
    border-radius: 12px;
}


.myBar{
    border-radius: 12px;
    height: 15px;
}

.account-details-modals .ant-switch-checked{
    background: #276DD7;
}

.account-details-modals .ant-card-body{
    padding: 5px !important;
    height: 100%;
}

.renewal-modal .ant-card-body{
    overflow-y: auto;
}