.inprogress-btn {
  background: #ffffff !important;
  border: 1px solid #dddddd !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  height: 44px !important;
  width: 188px !important;
  font-size: 1rem;
}

.accept-btn-text {
  font-family: Avenir;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 25px;

  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  &.white {
    color: #ffffff;
  }
  &.red {
    color: #d93025 !important;
  }
}

.bottom-bar {
  background-color: #ffffff !important;
  width: calc(100vw - 80px) !important;
  justify-content: flex-end;
}

.model-inprogress-box {
  margin: 20px 20px;
}
