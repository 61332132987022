.scene-select-container {
    padding: 20px;
    justify-content: center;
}

.scene-select-sub-container {
    width: 60%;
    flex-direction: column;
    align-items: center;
}

.scene-name-container {
    width: 70%;
    flex-direction: column;
    padding-top: 10px;
}

.subheading {
    font-size: 16px;
    font-weight: 500;
}

.heading {
    font-size: 24px;
    font-weight: 500;
}

.text {
    font-size: 14px;
    font-weight: 200;
}

.info-icon {
    color: #276DD7;
    margin-left: 5px;
}

.scene-card {
    cursor: pointer;
    border: 1px solid grey;
    border-radius: 6px;
    min-height: 350px;
    overflow: hidden;

    &:hover {
        border: 1px solid #276DD7;
        box-shadow: 0px 2px 8px 0px #00000026;
    }
}

.card-image {
    width: 100%;
}

.card-content {
    padding: 24px;
    text-align: center;
}

.spaces-list {
    margin-top: 20px;
    width: 100%;
}

.button-checked {
    background: #276DD7 !important;
    color: white !important;
}

.bottom-menu {
    z-index: 10;
    position: fixed;
    background: white;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    height: 80px;
    bottom: 0;
    left: 0;
    right: 0;
}

.filters-bar {
    justify-content: space-between;
    margin: 20px 0px;
}

.room-card {
    height: 370px;
    margin: 10px;
}