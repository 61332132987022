.swap-modal {
    top: 20px;
    width: 100% !important;
    height: 90% !important;
  
    .ant-modal-footer {
      padding: 0 !important;
      padding: 0px 30px 30px 30px !important;
      border-top: none !important;
    }

    .ant-modal-body {
      height: 70%;
    }
  }

.swap-container {
    padding: 10px 30px;
    position: relative;
}

.swap-heading-tag {
    background: #F0F0F0;
    padding: 5px 10px;
    border-radius: 4px;
    width: 115px;
    text-align: center;
}

.reset-heading-tag {
  width: 200px;
}

.header-container {
    padding: 0px 30px;
    padding-top: 40px;
}

.swap-modal-footer {
  padding: 10px 30px 10px;
  width: 100%;
  margin: 0px auto;
  border-radius: 4px;
  background: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: 0;
}

.modal-tab {
  height: calc(100vh - 280px);
}

.swap-info-text {
  position: absolute;
  right: 40px;
  top: 16px;
  color: #276DD7;
}

.swap-tooltip {
  .ant-tooltip-inner {
    background-color: #2B3240;
  }
}

.swap-list-loader {
    position: absolute;
    z-index: 10;
    bottom: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.reset-space-button {
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #1677ff;
  cursor: pointer;
}