.footer-bg {
    background: #2B3240;
    // padding: 70px 56px 70px 18px;
    padding: 70px 5% 70px 5%;
    justify-content: space-between;
}


.app-store-box {
    border: 1px solid white;
}

.footer-grid {
    display: grid !important;
    grid-template-columns: auto auto auto;
    gap: 16px;
}

.flex-imp {
    display: flex !important;
}

.align-end {
    align-items: flex-end;
}

.direction-flex {
    &.row {
        flex-direction: row;
    }

    &.col {
        flex-direction: column;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.all3d-logo-grid {
    display: grid !important;
    grid-template-columns: auto auto auto auto auto auto auto;
    gap: 2%;
}

.placeholder {
    &.black {
        &.ant-input::placeholder {
            color: rgba(89, 89, 89, 1);
            opacity: 1;
        }
        .ant-select-selection-placeholder {
            color: rgba(89, 89, 89, 1);
            opacity: 1;
        }
        .ant-input::placeholder {
            color: rgba(89, 89, 89, 1);
            opacity: 1;
        }
    }
}