$customColor: var(--custom-color, blue);

.background-color-class {
  color: $customColor;
}

.even-row {
    
}

.odd-row {
    background-color: #f4f4f4;
    .ant-table-cell:hover {
        background: aliceblue;
    }
    &.ant-table-tbody > tr.ant-table-row:hover > td {
        background  : aliceblue;
    }
}