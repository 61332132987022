.guidelines-heading{
    padding-top: 25px;
    padding-bottom: 20px;
}

.content-container {

    padding-left: 77px;
    padding-right: 77px;
    padding-bottom: 92px;

}