.ant-layout {
  background: white !important;
}
.ant-layout-sider {
  margin-left: 20px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.15);
}

.layout-container {
  background: #fff;
  overflow-y: scroll;
  height: calc(100vh - 80px);
  padding-left: 24px;
  padding-right: 24px;
}

.background-snow {
  .ant-layout-content {
        -ms-flex: auto;
        flex: auto;
        background: #f9f9f9;
        min-height: 0;
    }
}
li.ant-menu-item-selected::after {
  border-right: none !important; /* Remove the blue right border */
}
.add-product-layout-container {
  background: #fff;
  height: calc(100vh - 100px);
}
.ant-layout-sider-children{
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu-item:active, .ant-menu-submenu-title:active {
    background-color: #ffffff;
  }
  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color:#D93025;
  }
  .ant-menu-item-selected {
    color:#D93025;
  }
  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
   // border-left: 3px solid red;
  }
}
#root{
  .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: #D93025;
    border-bottom: 2px solid #D93025;
  }
}
::backdrop
{
    background-color: white;
}

.ml-loader{
  display: block;
  text-align: center;
  margin: auto;
  width: 100%;
  height: 100vh;
  position: relative;
  .anticon{
    position: absolute;
    top: 30%;
    text-align: center;
    font-size: 20px;
  }
}

.layout-snow {
  background-color: #F9F9F9
}

.footer-layout {
  .ant-layout-footer {
    padding: 24px;

  }

  .footer-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    width: 100%;
    height: 92px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF;

  }

}