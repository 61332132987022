
  .project-entity-card {
    background-color: #fff;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    transition: transform 0.3s;
    position: relative;
    cursor: pointer;
    max-width: 400px;
    margin: 20px 0;
    padding-bottom: 10px;
  }

  .tag-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .tag {
    background: #F0F0F0;
    padding: 5px 10px;
    color: black;
    border-radius: 5px;
    margin: 5px;
  }

  .prop-tag {
    background: #F9F0FF;
    color: #531DAB;
  }

  .product-tag {
    background: #F0F5FF;
    color: #1D39C4;
  }

  .space-tag {
    background: #FFFBE6;
    color: #D48806
  }

  .scene-tag {
    background: #E6FFFB;
    color: #08979C;
  }

  .today-tag {
    background: #F6FFED;
    color: #135200;
  }

  .project-name {
    color: black;
    text-align: start;
    padding: 2px 8px;
    height: 50px;
  }
  
  .status {
    height: 24px;
    color: black;
    text-align: start;
    padding-left: 8px;
  }

  .projects-nav-bar {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }

  .meta-data {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
    border-radius: 4px;
    background: #F0F0F0;
    color: black;
  }

  .invite-button {
    height: 40px;
    background: #276DD7;
    
    &.black {
      border: 1px solid #000000;
      color: white;
      background: #000000 !important;

      &:hover {
          border: 1px solid #000000;
          color: white;
          background: #000000 !important;
      }
    }
  }

  .card-name {
    word-wrap: break-word;
  }

  .bottom-menu {
    z-index: 1;
    position: fixed;
    background: white;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    height: 72px;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    box-shadow: 0px 0px 24px 0px #0000000F;
}

  .approve-button {
    color: white;
    background: #389E0D;
  }

  .project-page-header {
    padding-right: 0px !important;
  }

  .approve-modal-project {
    .ant-modal-body {
      padding: 0px;
    }
  }
  
  .comments-drawer {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  .comments-container {
    height: 70%;
    overflow-y: auto;
  }

  .add-comment-container {
    padding: 10px;
  }

  .comment-header {
    padding: 20px;
    height: 8%;
  }

  .message-divider {
    margin: 0px;
  }

  .message {
    padding: 10px 2px;
  }

  .comment-input {
    background: #FAFAFA;
    padding: 6px;
  }

  // full screen blurred background
  .blur-background {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px); /* Adjust the blur intensity as needed */
    background-color: rgba(255, 255, 255, 0.5); /* Adjust the background color and transparency */
  }

  .comment-annotation-circle {
    position: relative;
    width: 25px; 
    height: 25px;
  }
  
  .comment-annotation-circle img {
    width: 100%;
    height: 100%;
  }
  
  .comment-count {
    position: absolute;
    top: -7px; 
    right: -5px;
    background-color: red; 
    color: white;
    border-radius: 50%;
    padding: 2px 7px; 
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .comment-col {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
    padding-top: 12px;
  }

  .image-container {
    width: 100%;
    padding-top: 60%; /* Adjust this to control the aspect ratio */
    position: relative;
    background: white; /* Fills the remaining space with white color */
}

.image-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
}

.silo-image-card {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.dummy-height {
  height: 30px;
  &.scene {
    height: 25px;
  }

  &.approved_space {
    height: 40px;
  }
}

.lifestyle-name-settings {
  margin-top: 11px;
  width: fit-content;
  flex-direction: column;
  justify-content: flex-end
}