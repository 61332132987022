.view-360-container {
    height: 500px;
    /* Set a fixed height or percentage relative to its parent */
    width: 100%;
    /* Set width to 100% of the container or parent */
    position: relative;
    /* Ensure that child elements can position relative to this */
}

#container-360 {
    width: 100% !important;
    /* Ensure canvas width is 100% of its container */
    //height: 100% !important;
    /* Ensure canvas height is 100% of its container */
}

.cloudimage-360 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    height: 100%;
    /* Ensures that the canvas takes 100% of the parent container height */
    position: relative;
    /* Ensures positioning context for canvas */
}

.cloudimage-360 canvas {
    width: 100% !important;
    /* Width auto so it scales proportionally */
    height: 100% !important;
    /* Height is set to 100% of the container */
    object-fit: contain;
    /* Optionally, you can set 'contain' if you want to preserve aspect ratio */
}

.cloudimage-360 .cloudimage-inner-box {
    width: 100% !important;
    /* Ensure canvas width is 100% of its container */
    height: 100% !important;
}