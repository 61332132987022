@import "../../styles/styles";

.App {
    text-align: center;
}

nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 10vh;
    background: rgb(73, 79, 82);
    color: white;
}

.nav-links {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
}

.nav-links li {
    color: white;
}

.avatarImage {
    max-height: 30px;
}

html body {
    background-color: white;
}

.center-aligned {
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form {
    width: 100%;
    @media (min-width: 992px) {
        width: 500px;
        padding: 0 1%;
    }
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}

.logo {
    width: 120px;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 28px 16px 0;
    float: left;
}

//
//.user-details {
//  border-color: white;
//  border-style: solid;
//  display: flex;
//  justify-content: space-between;
//  width: 116px;
//  margin-top: 15px;
//  margin-left: 145px;
//  border-radius: 10px;
//}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.filter-box.d-inline {
    .ant-select-selector {
        height: 40px;
    }
}

.ant-menu-root {

    .ant-menu-item .anticon,
    .ant-menu-submenu-title .anticon svg {
        width: 28px;
        position: relative;
        left: -6px;
        top: 6px;
    }
}

.password-frm {
    .ant-input-affix-wrapper {
        border: none;
        padding: 4px 0;
    }

    .ant-input-affix-wrapper>input.ant-input {
        padding: 0 20px;

    }

    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
        border-color: #ffffff;
        border-right-width: 1px !important;
        outline: 0;
        -webkit-box-shadow: 0 0 white;
        box-shadow: 0 0 white;
    }

    .ant-input-prefix {
        margin-right: 15px;
    }
}

.custom-frm {
    .ant-input-affix-wrapper {
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: inherit;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: none;
        border-radius: 2px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        display: -ms-inline-flexbox;
        display: inline-flex;
        max-height: inherit;
    }

    .ant-input-prefix {
        margin-right: 0px;
    }

    .ant-input-affix-wrapper>input.ant-input {
        padding-left: 11px;
    }

    .ant-form-item-control-input-content {
        position: relative;

        .anticon.anticon-user,
        .anticon.anticon-lock,
        .anticon.anticon-mail,
        .anticon.anticon-code,
        .anticon.anticon-team,
        .anticon.anticon-idcard {
            position: absolute;
            top: 35%;
            left: 2%;
        }
    }

    .ant-form-item-control {
        position: relative;
        line-height: 40px;
        zoom: 1;
    }
}

.login-frm {
    .ant-input-affix-wrapper {
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: inherit;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: none;
        border-radius: 2px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        display: -ms-inline-flexbox;
        display: inline-flex;
        max-height: inherit;
    }

    .ant-input-prefix {
        margin-right: 0px;
    }

    .ant-input-affix-wrapper>input.ant-input {
        padding-left: 11px;
    }

    .ant-form-item-control-input-content {
        position: relative;

        .anticon.anticon-user,
        .anticon.anticon-lock,
        .anticon.anticon-mail,
        .anticon.anticon-code,
        .anticon.anticon-team,
        .anticon.anticon-idcard {
            position: absolute;
            top: 35%;
            left: 2%;
        }
    }

    .ant-form-item-control {
        position: relative;
        line-height: 40px;
        zoom: 1;
    }

    .ant-select-selection {
        border-radius: 4px !important;
        border: 1px solid #e3e3e3 !important;
        background-color: white !important;
        height: 50px !important;
        color: #333333;
    }


    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #333333;
        border-color: #333333;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus+.ant-checkbox-inner {
        border-color: #333333;
    }

    .ant-input {
        border-radius: 4px !important;
        border: solid 1px #e3e3e3 !important;
        background-color: white !important;
        height: 45px !important;
        color: #333333;
    }

    .medium-input-box {
        border-radius: 4px !important;
        border: solid 1px #e3e3e3 !important;
        background-color: white !important;
        height: 45px !important;
        color: #464545;
    }

    label {
        font-size: 16px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #333333;
    }

    .ant-form-item-required::before {
        color: #333333;
    }

    .ant-select-selection,
    .ant-select-selector {
        border-radius: 4px !important;
        border: solid 1px #e3e3e3 !important;
        background-color: white !important;
        height: 45px !important;
        min-height: 45px !important;
        max-height: max-content !important;
        color: #333333;
        align-items: center;
    }

    .custom_dimentions_unit {

        .ant-select-selection,
        .ant-select-selector {
            border-radius: 4px !important;
            border: solid 1px #e3e3e3 !important;
            background-color: rgba(244, 244, 244, 0.5) !important;
            height: 32px !important;
            color: #333333;
        }
    }
}

.bVhcyw {
    border: none !important;
}

.username-frm-control {
    &.ant-form-item, .ant-legacy-form-item {
        margin-bottom: 0px !important;
    }
}

.login-page-mobile-img {
    width: 100%;
    height: 1102px;
    object-fit: contain;
}