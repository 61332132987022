  .entity-card {
    background-color: #fff;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    transition: transform 0.3s;
    position: relative;
    cursor: pointer;
    max-width: 400px;
    padding-bottom: 10px;
  }

  .tag-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .tag {
    background: #F0F0F0;
    padding: 5px 10px;
    color: black;
    border-radius: 5px;
    margin: 5px;
  }

  .prop-tag {
    background: #F9F0FF;
    color: #531DAB;
  }
  .product-tag {
    background: #F0F5FF;
    color: "#1D39C4";
  }

  .space-tag {
    background: #FFFBE6;
    color: "#D48806"
  }

  .scene-tag {
    background: #E6FFFB;
    color: #08979C;
  }

  .today-tag {
    background: #F6FFED;
    color: #135200;
  }
 
  .status {
    height: 24px;
    color: black;
    text-align: start;
    padding-left: 8px;
  }

  .card-name {
    word-wrap: break-word;
  }

  .entity-name {
    color: black;
    text-align: start;
    padding: 2px 8px;
    height: 50px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .status {
    height: 24px;
    color: black;
    text-align: start;
    padding-left: 8px;
  }

  .card-icon {
    right: 20px !important;
    top: 20px !important;
  }

  .card-button-checked {
    background: black !important;
    color: white !important;
}

.product-hover {
    .suggested-product {
        opacity: 0.5;
        cursor: not-allowed !important;
    }
}