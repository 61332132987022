.change-log-modal-pd {
    padding: 44px 30px;
}

.change-log-image-dim {
    height: 350px;
    width: 100%;
    object-fit: contain;
}

.change-log-modal-width {
    width: 50% !important;
}

.change-log-carousel-dots {
    border-radius: 50%;
    background: #00000040;
    width: 8px;
    height: 8px;
    cursor: pointer;
    &.active {
        background: #276DD7
    }    
}

.change-log-feature-type-bg {
    margin: auto;
    width: fit-content;
    border-radius: 4px;
    background: #E6F7FF;
    padding: 8px;
}