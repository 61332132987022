.add-product-modal {
  top: 20px;
  width: 100% !important;
  height: 90% !important;

  .ant-modal-footer {
    padding: 0 !important;
    padding: 0px 30px 30px 30px !important;
    border-top: none !important;
  }

  .ant-modal-body {
    height: 70%;
  }
}

.store-text {
  width: 100px;
  text-align: center;
}

.filter-btn-icon {
  width: 38px !important;
  height: 38px !important;
}

.filter-icon {
  svg {
    display: flex;
  }
}

.add-product-modal-footer {
  padding: 10px 30px 10px;
  width: 100%;
  margin: 0px auto;
  border-radius: 4px;
  background: white;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}