.analytics-graphs-section {
  margin-top: 5px;
  .ant-card-body {
    padding: 0px !important;
  }
  .heading {

    font-size: 24px;
    margin: 15px;
  }
}

.count-card {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  .heading {
    background-color: rgba(255,255,255,0.0);
    font-size: 14.0px;
    color: rgba(153, 153, 153, 1.0);
    padding-left: 10px;
    text-transform: uppercase;
  }
  .count {
    font-size: 3rem;
    color: #333333;
    padding-left: 10px;
    line-height: 4rem;
    padding-left: 10px;
  }
  .count-icon {
    font-size: 30px;
    margin-top: 12px;
  }
  .ant-card-body {
    padding: 14px;
  }
}
.suggested-product {
  .product-image {
    width: 100%;
  }
  .product-name {
    font-size: 1.5rem;
    color: rgba(51, 51, 51, 1.0);
    text-align: center;
    line-height: 120%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: nowrap;
    overflow: hidden;
    //display: -webkit-box;
    display: block;
    text-overflow: ellipsis;
    //width: -webkit-fill-available;
    width: inherit;
  }
  .product-detail {
    font-size: 1.4rem;
    color: rgba(153, 153, 153, 1.0);
    text-align: center;
    line-height: 25.0px;
  }
  .product-price {
    font-size: 1rem;
    color: rgba(217, 48, 37, 1.0);
    text-align: center;
    line-height: 27.0px;
  }
}