input[type="radio"] {
  display: none;
}

.label-text {
  margin: 0;
  font-weight: bold;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.434);
  cursor: pointer;
  &.selected {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }
  &:hover {
    color: rgba(0, 0, 0, 0.173);;
  }
}

.main-container {
  font-family: Manrope;
  display: inline-block;
  vertical-align: middle;
  width: 80%;
  height: 38px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.088);
  top: 20%;
}

.switch {
  height: 38px;
  width: 27%;
  background-color: #205FCF;
  border-radius: 10px;
  position: absolute;
  transition: ease-in;
}

/*---------------- LEFT ------------------- */
.switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}

.switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.switch.left-position {
//   left: 3px;
}

.left-label {
  position: absolute;
}

#left + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 27%;
  border-radius: 10px;
}

#left:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 27%;
  border-radius: 10px;
}

@keyframes leftToCenter {
  from {
    left: 0%;
  }
  to {
    left: 27%;
  }
}

@keyframes leftToRight {
  from {
    left: 0px;
  }
  to {
    left: 54%;
  }
}

/* -------------------- center ------------------ */
.switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.switch.center-position {
  left: 27%;
}

.center-label {
  position: absolute;
  left: 27%;
}

#center + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 27%;
  border-radius: 10px;
}

#center:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 27%;
  border-radius: 10px;
}

@keyframes centerToLeft {
  from {
    left: 27%;
  }
  to {
    left: 0%;
  }
}

@keyframes centerToRight {
  from {
    left: 27%;
  }
  to {
    left: 54%;
  }
}

/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.switch.right-position {
  left: 54%;
}

.right-label {
  position: absolute;
  left: 54%;
}

#right + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 27%;
  border-radius: 10px;
}

#right:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 27%;
  border-radius: 10px;
}

@keyframes rightToLeft {
  from {
    left: 54%;
  }
  to {
    left: 0%;
  }
}

@keyframes rightToCenter {
  from {
    left: 54%;
  }
  to {
    left: 27%;
  }
}
