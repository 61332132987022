    .details-container {

        height: max-content;
        width: 100%;

        .title {
            display: flex;
            align-items: center;
            text-align: center;
            line-height: 22px;
            letter-spacing: 0.005em;
            padding-left: 20px;
            height: 56px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            background-color: #FFFFFF;
        }

        .tags-container {
            display: flex;
            align-items: center;
            text-align: center;
            flex-wrap: wrap;
            padding: 20px;
            width: 100%;
            height: max-content;
            background-color: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.12);

            .values-container {
                height: max-content;
                display: flex;
                align-items: flex-start;
                color: #666666;
                flex-direction: column;
                margin-top: 10px;
                margin-bottom:10px;
                margin-right:94px;

                .text-grey {
                    color: #333333;
                }

            }

            .portfolio-container{
                height: max-content;
                width: 100%;
                background-color: rgba(217, 217, 217, 0.2);
                padding: 8px 12px;
                border-radius: 4px;
                border: 1px solid #D9D9D9;
                margin-bottom: 10px;
                .portfolio-file-container{
                    display: flex;
                }
                &:hover{
                    cursor: pointer;
                }
            }

        }
    }