.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #276DD7;
    font-size: 14px;
    font-family: Manrope;
    font-weight: 600;
}

.ant-tabs-tab-btn{
    font-size: 14px;
    font-family: Manrope;
    font-weight: 600;
}

.ant-tabs-ink-bar{
    background: #276DD7;
}

.grey-background{
    background: rgba(248, 248, 251, 0.6);;
}