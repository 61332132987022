.collaborate-navbar {
    width: 100%;
    padding: 20px 16px;
    border-radius: 4px;
    background-color: white;
    display: flex;
}

.collaborate-container {
    height: calc(100vh - 150px);
    background: #F4F4F4;;

    &.qa {
        height: calc(100vh - 270px);
        background: #F4F4F4;
    }
    &.qa-editor {
        height: calc(100vh - 215px);
        background: #F4F4F4;
    }
}

.blur-canvas {
    filter: blur(12px);
}

.on-start-load {
    &.warning-icon {
        text-align: center;
        font-size: 24px;
        color: red;
        margin-bottom: 10px;
    }

    &.loading-icon {
        text-align: center;
        font-size: 24px;
    }
}

.access-icon {
    font-size: 28px;
    color: #7c7777;
    cursor: not-allowed;
}


.display-flex {
    display: flex;

    &.j-s {
        justify-content: flex-start;
    }

    &.j-c {
        justify-content: center;
    }

    &.j-s-b {
        justify-content: space-between;
    }

    &.j-e {
        justify-content: flex-end;
    }

    &.a-c {
        align-items: center;
    }

    &.a-baseline {
        align-items: baseline;
    }

    &.a-s {
        align-items: flex-start;
    }

    &.a-e {
        align-items: flex-end;
    }

    &.sa-s {
        align-self: flex-start;
    }

    &.wrap {
        flex-wrap: wrap;
    }

    &.no-wrap {
        flex-wrap: nowrap;
    }

    &.dir-col {
        flex-direction: column;
    }
}

.basic-collab-btn {
    width: unset !important;
    height: unset !important;
    padding: 0 !important;
    color: #276DD7 !important;
    border: 1px solid #276DD7 !important;
    background: #FFFFFF !important;
    border-radius: 2px !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);

    &.ant-btn[disabled],
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        background: #FFFFFF !important;
        border: 1px solid #D9D9D9 !important;
        text-shadow: none;
        opacity: 0.5 !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.ant-btn[disabled]:hover {
        color:#276DD7 !important
    }

    &.ant-tooltip-disabled-compatible-wrapper {
        background: #FFFFFF !important;
        border: 1px solid #D9D9D9 !important;
        color: #276DD7 !important;
        opacity: 0.5 !important;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.f-14 {
        font-size: 14px !important;
        line-height: 22px;
        padding: 8px 15px !important;
        font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        vertical-align: middle;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
    }

    &:hover {
        background-color: #276DD7 !important;
        color: #FFFFFF !important;
        border: 1px solid #276DD7 !important;
    }

    &.green {
        border: 1px solid #52C41A !important;
        color: #52C41A !important;

        &:hover {
            background-color: #52C41A !important;
            color: #FFFFFF !important;
        }
    }
}

.basic-collab-btn {
    width: unset !important;
    height: unset !important;
    padding: 0 !important;
    color: black !important;
    background: #FFFFFF !important;
    border-radius: 2px !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    border: 1px solid #D9D9D9 !important;
    &.ant-btn[disabled],
    .ant-btn[disabled]:hover,
    .ant-btn[disabled]:focus,
    .ant-btn[disabled]:active {
        background: #FFFFFF !important;
        border: 1px solid #D9D9D9 !important;
        text-shadow: none;
        opacity: 0.5 !important;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.ant-btn[disabled]:hover {
        color:#276DD7 !important
    }

    &.ant-tooltip-disabled-compatible-wrapper {
        background: #FFFFFF !important;
        border: 1px solid #D9D9D9 !important;
        color: #276DD7 !important;
        opacity: 0.5 !important;
        text-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    &.f-14 {
        font-size: 14px !important;
        line-height: 22px;
        padding: 8px 15px !important;
        font-family: Manrope;
        font-style: normal;
        font-weight: 500;
        vertical-align: middle;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
    }

    &:hover {
        color: #276DD7 !important;
        border: 1px solid #276DD7 !important;
    }

}

.collaborate-toolbar {
    padding: 8px 16px;
    width: 100%;
    margin: 0px auto;
    border-radius: 4px;
    background: white;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    &.qa {
        border-radius: 0px;
        padding: 5px 16px;
        border-left: 0px;
        border-right: 1px solid #d8d7d7;
        border-top: 1px solid #d8d7d7;
        border-bottom: 1px solid #d8d7d7;
        background: white;
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    }
}

.image-list-qa {
    border-right: 1px solid #d8d7d7;
}

.tool-box {
    cursor: pointer;
    width: 45px;
    height: 45px;
    background: white;
    border-radius: 4px;

    &.disable {
        cursor: not-allowed;
        pointer-events: none;
    }

    &:hover {
        background: rgba(9, 109, 217, 0.08);
    }

    &.selected {
        background: rgba(9, 109, 217, 0.08);
    }
}

.tool-box-text {
    cursor: pointer;
    margin: 4px 0px;
    border-radius: 4px;
    border-radius: 0px;
    border: 1px solid #D9D9D9;
    background: #F5F5F5;
    padding: 4px;

    &:hover {
        background: rgba(9, 109, 217, 0.08);
        color: white;
    }

    &.selected {
        background: rgba(9, 109, 217, 0.08);
        color: white;
    }

    .tool-box-text-color {
        width: 60px;

        &.thin {
            border-bottom: 2px solid black;
        }

        &.medium {
            border-bottom: 6px solid black;
        }

        &.thick {
            border-bottom: 8px solid black;
        }

        &:hover {
            color: white;
        }
    }
}

.tool-icons {
    color: #595959;
    padding: 10px;
    width: 45px;
    height: 45px;
}

.collab-divider-container {
    width: 45px;
    height: 45px;
    background: white;
    border-radius: 4px;
}

.collab-tool-divider {
    border-radius: 100px;
    background: #d9d9d9;
    width: 1px;
    height: 100%;
    display: flex;
    margin: auto;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
}

.annotate-top-bar {
    padding: 8px;
    border-bottom: 1px solid #D9D9D9;
}

.annotate-box {
    width: 28%;
    height: max-content;
    position: absolute;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    top: 100px;
    background: white;
    border-radius: 4px;
    z-index: 5;
}

.annotation-modal {
    .ant-modal-footer {
        display: none;
    }

    .ant-modal-body {
        padding: 1px;
    }
}

.collaborate-access-modal {
    .ant-modal-footer {
        display: none;
    }

    .ant-modal-header {
        padding: 16px;
    }

    .ant-modal-body {
        padding: 16px;
    }
}

.comparison-image-popup {
    width: 60% !important;
    .ant-modal-header {
        padding: 16px;
    }

    .ant-modal-body {
        padding: 16px;
    }
}

.comment-input-box {
    color: rgba(0, 0, 0, 0.45);
    background: #FAFAFA;
    padding: 12px;
    border: 1px solid #D9D9D9;

    textarea {
        height: 50px;
        color: rgba(0, 0, 0, 0.45);
        background: #FAFAFA;
        border-radius: 0px;
        padding: 12px;
        border: 1px solid #D9D9D9;

        &:focus-visible {
            outline: 1px solid #3bbcf1a6 !important;
        }
    }

    ul {
        width: 200px;

        li {
            padding: 8px 12px !important;
            border-bottom: 1px solid lightgrey;

            &:hover {
                background: #e0e0e0;
            }
        }
    }

}


.comment-input-box__suggestions {

    /* width */
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(136, 136, 136, 0.81);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(85, 85, 85, 0.73);
    }
}

.comment-list {
    justify-content: center;
}

.action-icon-btn {
    &.blue {
        color: #276DD7;
    }

    &.red {
        color: #D93025;
    }

    &.grey {
        color: #595959;

        &:hover {
            border: none;
            color: #276DD7;
        }
    }

    &.green {
        color: #52C41A;
    }

    border: none;
    box-shadow: none;

    &:hover {
        border: none;
    }
}

.comment-upload-box {
    .ant-upload-select {
        padding: 0px !important;
        height: auto !important;
        border: none !important;
        background-color: transparent !important;
    }

    .ant-upload-list-text-container {
        width: 80px !important;
    }

    .ant-upload-list-item-progress {
        top: 0px !important;
        position: unset !important;
        padding-left: 0px !important;
    }

    .ant-upload-list-item {
        margin-top: 0px !important;
        height: auto !important;
    }
}

.comment-action-container {
    background: #FAFAFA;
    padding: 8px;
    border: 1px solid #D9D9D9;
}

.add-comment-bar {
    padding: 12px;

    &.pd-0 {
        padding: 0px;
    }
}

.comment-container {
    min-height: fit-content;
    max-height: 280px;
    overflow-y: auto;
    &.qa {
        max-height: 100px;
    }
}

.comment-box {
    padding: 8px 12px;
    width: 100%;
}

/* width */
.custom-scroll::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
    background: transparent
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.81);
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 0.73);
}


.comment-img {
    width: 100%;
    height: 45px;
    object-fit: cover;

    &.pd-10 {
        padding: 10px;
    }
}

.comment-img-container {
    max-width: 100px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}

#canvas-editor {
    width: 100%;
    height: calc(100vh - 140px);
    /* Adjust this value as needed */
    position: relative;
    overflow: hidden;
}

#canvas-editor-qa {
    width: 100%;
    height: calc(100vh - 270px);

    /* Adjust this value as needed */
    position: relative;
    overflow: hidden;
}

.sub-toolbar {
    .ant-popover-inner-content {
        padding: 10px;
    }
}

.object-color-input {
    padding: 0px 2px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}


.color-input-wrapper {
    height: 1.75em;
    width: 1.75em;
    overflow: hidden;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    position: relative;
    margin: 10px;

    &:hover {
        background: rgba(9, 109, 217, 0.08);
    }

    &.selected {
        background: rgba(9, 109, 217, 0.08);
    }
}

.color-input-wrapper .ant-input[type=color] {
    position: absolute;
    height: 4em;
    width: 4em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    border: none;
    margin: 0;
    padding: 0;

    &:hover {
        background: rgba(9, 109, 217, 0.08);
    }

    &.selected {
        background: rgba(9, 109, 217, 0.08);
    }
}

#fabric-canvas {
    &.fabric-container {
        .canvas-container {
            margin: auto;
            background: white;
        }
    }


    &.erase {
        .canvas-container {
            .upper-canvas {
                cursor: url('/img/collaborate_tool/erase_cursor.cur'), auto !important;
            }
        }
    }

    &.annotate {
        .canvas-container {
            .upper-canvas {
                cursor: url('/img/collaborate_tool/comment_cursor.cur'), auto !important;
            }
        }
    }

    &.pan {
        .canvas-container {
            .upper-canvas {
                cursor: grab !important;
            }
        }
    }

    &.zoom_in {
        .canvas-container {
            .upper-canvas {
                cursor: zoom-in !important;
            }
        }
    }

    &.zoom_out {
        .canvas-container {
            .upper-canvas {
                cursor: zoom-out !important;
            }
        }
    }
}

.comment-error-msg {
    text-align: left;
    padding: 8px;
}

.collaborators-list-item {
    background-color: #FAFAFA;
    padding: 8px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
    border: 1px solid #D9D9D9;
}

.collaborators-access>.ant-select-selector {
    background-color: #FAFAFA !important;
    border: none !important;
}

.collaborators-access {
    color: #276DD7
}

.collaborators-list {
    overflow-y: scroll;
    max-height: 40vh;
    margin-top: 15px
}


.collab-access-form-fields {
    height: 40px;

    .ant-select-selector {
        height: 40px !important;

        .ant-select-selection-item {
            line-height: 35px !important;
        }
    }
}


.collab-nav-divider {
    height: 38px !important;
    margin-left: 12px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.version-history-icon {
    padding: 8px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border: 1px solid #D9D9D9;
    border-radius: 4px;

    &:hover {
        border: 1px solid #276DD7;
        background-color: #276DD7;
    }
}


.history-img {
    width: 445px;
    height: 251px;
    object-fit: contain;
    border-radius: 4px;
    border: 1px solid #E9E9E9;

}

.version-history-drawer {
    .ant-drawer-content-wrapper {
        width: max-content !important;
        max-width: 500px !important;
    }

    .ant-drawer-header {
        padding: 16px !important;
    }
}

#comment_drawer {
    display: none;
}

.comment-history-drawer {
    z-index: 3;
    position: absolute;
    cursor: move;
    right: 2%;
    top: 12%;
    width: 400px;
    border-radius: 4px;
    background: white;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
    &.qa {
        right: 1%;
        top: 16%;
    }
}

.comment-drawer-header {
    padding: 16px !important;
    border-bottom: 1px solid #d7d6d6;
}

.line-height-20 {
    line-height: 20px !important;
}

.pd-r-13 {
    padding-right: 10px;
}

.pd-t-10 {
    padding-top: 10px;
}

.pd-b-10 {
    padding-bottom: 10px;
}

.comment-item-container {
    padding-bottom: 8px;
    padding-top: 16px;
    border-bottom: 1px solid #D9D9D9;
    background: #FFFFFF;

    &.blue {
        background: #add8e630;

        &:hover {
            background: #add8e672;
        }
    }

    &:hover {
        background: #F4F4F4;
    }
}

.comment-list-container {
    min-height: max-content;
    max-height: calc(100vh - 220px);
    overflow-y: scroll;
    &.qa {
        max-height: calc(100vh - 255px);
    }
}

.comment-option-container {
    border-bottom: 1px solid #D9D9D9;
    background: #FFFFFF;
    cursor: pointer;

}


.comment-selector {
    width: 240px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 2%;

    li {
        height: 30px !important;
        line-height: 28px !important;
        background: white;
        margin: 0px !important;

        &:hover {
            background: #F4F4F4;
        }
    }


    &:hover {
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 2%;
    }
}

.comment-option-dropdown {
    width: 240px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);

    opacity: 1;
    background: white;
}

.resolved-comment-checkbox {
    font-family: Manrope;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: 2%;
}

.comment-pd-space {
    padding: 0 16px 4px;
}

.comment-option-divider {
    margin: 0px 0px 4px 0px;
}

#annotate-modal {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.version-tag {
    background: rgba(0,0,0,.2);
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0px 5px;
    border-radius: 2px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.open-icon {
    filter: invert(1);
    margin-left: 4px;
    height: 20px;
}

.version-card {
    position: relative;
    cursor: pointer;
}

.download-icon {
    background: rgba(20,20,20,.4);
    padding: 4px;
}

.nameEditField {
    padding-right: 5px;
    border: 1px solid #D9D9D9 !important;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}

.nameIcon {
    padding-right: 6px;
}

.nameEditIcons {
    display: flex;
    justify-content: center;
    align-items: center;
}